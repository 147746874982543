import { ClickState } from './click-state';

export const getCarPrice = (car: CarResult) => {
  if (car.proposal?.rank_target_value) {
    return car.proposal.rank_target_value;
  }
  if (car.rank?.target_value) {
    return car.rank?.target_value;
  }
  return undefined;
};

export interface CarResult {
  hidden?: boolean;
  body?: string;
  body_original?: string;
  color?: string;
  has_price_proposal?: boolean;
  image?: string;
  rank?: Rank;
  price?: Price;
  dealer?: Dealer;
  location_name?: string;
  url_gaspedaal?: string;
  url_autoscout24?: string;
  url_mobile?: string;
  make?: string;
  id: number;
  click?: Click;
  equipment?: Equipment;
  location?: string;
  fuel?: string;
  type?: string;
  hp?: number;
  build?: number;
  topdown_value?: number;
  topdown_value_at_maturity?: number;
  topdown_value_breakdown?: Record<string, number>;
  topdown_value_at_maturity_breakdown?: Record<string, number>;
  topdown_value_exex?: number;
  topdown_value_exex_breakdown?: Record<string, number>;
  status?: string;
  stat?: Stat;
  mileage?: number;
  gear?: string;
  sold?: Sold;
  supply_type?: string;
  license_plate?: string;
  car_type?: string;
  model_year?: number;
  url?: string;
  model?: string;
  proposal?: Proposal;
  turnover?: CarTurnover;
  taxes?: CarTaxes[];
  trace?: Trace;
  includes_banned?: boolean;
  is_banned?: boolean;
  reference_code?: string;
  vin?: string;
  stock_days_client?: number;
  stock_date_client?: string;
  extracted_options?: string[];
  standard_options?: string[];
  apr?: number;
  apr_breakdown?: Record<string, AprBreakdown>;
  country?: string;
  stock_days_average?: number;
  stock_days_average_in_stock?: number;
  stock_days?: number;
  stock_days_location?: number;
  stock_days_rdw?: number;
  vat?: number;
  options?: string;
  options_order?: string[];
  window_car_ids?: number[];
  latitude?: number;
  longitude?: number;
}

export interface Equipment {
  is_dealer?: boolean;
  four_doors?: boolean;
  specials?: boolean;
  in_stock?: boolean;
  official_make?: boolean;
}

export interface CarTurnover {
  etr: number[];
  itr: number[];
  days: number[];
  itr_trend?: number;
  etr_trend?: number;
}

export interface AverageTrendResult {
  etr_list: number[];
  itr_list: number[];
  days_list: number[];
}

export interface MapDataResult {
  latitude: number | undefined;
  longitude: number | undefined;
  dealer_name: string;
  price_local: number;
  image_url: string;
  location_name: string;
  id: number;
}

export interface CarTaxes {
  value: number;
  type: string;
  data: CarTaxesData;
  source: string;
  co2: number;
}

interface AprBreakdown {
  bound: number;
  points: number;
}

interface TraceRankDetails {
  percent?: number;
  value?: string;
  position: number;
  rule: string;
}

interface TraceRank {
  protocol: number;
  protocol_name: string;
  rank_target: number;
  rank_trace: TraceRankDetails[];
  rule: number;
  rule_type: string;
}

interface Trace {
  rank?: TraceRank[];
  window?: any;
  conditions?: any;
}

interface Proposal {
  car_id?: number;
  created?: string;
  id?: number;
  modified?: string;
  rank_target_value?: number;
}

export interface Click {
  model_year?: boolean;
  equipment_four_doors?: boolean;
  equipment_in_stock?: ClickState | undefined;
  own_supply?: boolean;
  hp?: boolean;
  equipment_is_dealer?: boolean;
  equipment_official_make?: boolean;
  build?: boolean;
  options_include?: string;
  options_exclude?: string;
  options?: string;
  dealer?: string;
  location?: boolean;
}

interface Dealer {
  logo?: string;
  name?: string;
}

interface Price {
  purchase?: number;
  local?: number;
  local_exex?: number;
  recalculate?: string;
  catalog?: number;
  history?: History[];
}

interface History {
  value: number;
  label: string;
}

interface Rank {
  total?: number;
  target?: number;
  counter?: number;
  target_discount?: number;
  target_perc?: number;
  current?: number;
  target_value_old?: number;
  target_value?: number;
  target_value_exex?: number;
  target_value_at_maturity?: number;
}

interface Sold {
  since?: number;
}

interface Stat {
  turnover_int?: number;
  leads?: number;
  price_warning?: number;
  apk_days?: number;
  t3_since?: number;
  turnover_int_trend?: number;
  turnover_ext_trend?: number;
  clicks?: number;
  own_supply_window_count?: number;
  price_start?: number;
  local_car?: boolean;
  turnover_ext?: number;
  sold_count?: number;
  stock_count?: number;
}

interface CarTaxesData {
  discount: number;
  price_catalog_average: number;
  value_init: number;
}
