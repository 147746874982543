import React from 'react';
import { MultiSelectFilter, StringAppSearchParam, StringListAppSearchParam, TextFilter } from '@japieglobal/shared';
import { CsmReportKeys, DealerAccountHealth } from '@japieglobal/shared/src/api/services';
import { ColumnHeaderFilterProps } from '@japieglobal/shared/src/components/table/types';
import { CsmColumn } from '../csm-report-columns';
import { statusOptions } from '../table-content/cell-content/status-select-cell';

const accountHealthOptions = [{ label: 'None', value: '' }].concat(
  Object.values(DealerAccountHealth).map((health) => ({ label: health, value: health })),
);

interface HeaderFilterWrapper {
  headerFilterProps: ColumnHeaderFilterProps;
  csmColumn: CsmColumn;
}
export const HeaderFilterWrapper = ({
  headerFilterProps: { colDef, inputRef },
  csmColumn: { headerName, searchParamKey },
}: HeaderFilterWrapper) => {
  switch (colDef.field) {
    case CsmReportKeys.COMMENTS:
      return undefined;
    case CsmReportKeys.HEALTH:
      return (
        <MultiSelectFilter
          hideArrowIcon
          hideResetButton
          labelTranslationKey="HEALTHS"
          staticOptions={accountHealthOptions}
          searchParamKey={searchParamKey as StringListAppSearchParam}
        />
      );
    case CsmReportKeys.STATUS:
      return (
        <MultiSelectFilter
          hideArrowIcon
          disableClearable
          hideResetButton
          labelTranslationKey="STATUSES"
          staticOptions={statusOptions}
          searchParamKey={searchParamKey as StringListAppSearchParam}
        />
      );
    default:
      return (
        <TextFilter
          hideResetButton
          labelTranslationKey={headerName}
          searchParamKey={searchParamKey as StringAppSearchParam}
          {...{ InputProps: { ref: inputRef } }}
        />
      );
  }
};
