import React from 'react';
import { MenuButton } from '../../menu-button';
import { Publish } from '@mui/icons-material';
import {TopRowMenuButtonProps} from "../../top-row";

export const FileValuationButton = ({ internal, user }: TopRowMenuButtonProps) => (
  <MenuButton hoverTitle={'FILE_VALUATION'} display={user?.permissions.includes('fileValuation')} internal={internal} link="/upload">
    <Publish />
  </MenuButton>
);
