import React from 'react';
import { ColoredButton, SelectOption } from '@japieglobal/shared';
import { useSnackbarErrorHandler } from '@japieglobal/shared/src/hooks';
import { Box } from '@mui/material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { useTranslation } from 'react-i18next';
import { MakeSelect } from '../selects/make-select';
import { FuelSelect } from '../selects/fuel-select';
import { BodySelect } from '../selects/body-select';
import { BuildSelect } from '../selects/build-select';
import { ModelSelect } from '../selects/model-select';
import { HpSelect } from '../selects/hp-select';
import { createStandardOption, deleteStandardOption, editStandardOption } from '@japieglobal/shared/src/api/services';
import { StandardOption } from '@japieglobal/shared/src/api/services';

interface StandardOptionsEditCreateProps {
  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;
  getStandardOptions: () => void;
  newStandardOption: StandardOption;
  setNewStandardOption: (newStandardOption: StandardOption) => void;
  selectedStandardOption?: StandardOption;
  setSelectedStandardOption: (newStandardOption?: StandardOption) => void;
  BASE_NEW_STANDARD_OPTION: StandardOption;
}
export const StandardOptionsEditCreate = ({
  isLoading,
  newStandardOption,
  selectedStandardOption,
  BASE_NEW_STANDARD_OPTION,
  setIsLoading,
  getStandardOptions,
  setNewStandardOption,
  setSelectedStandardOption,
}: StandardOptionsEditCreateProps) => {
  const { t } = useTranslation();
  const { snackbarErrorHandler, snackbarSuccessMessage, snackbarErrorMessage } = useSnackbarErrorHandler();

  const carPropToOption = (value?: string | number | null): SelectOption => ({
    value: String(value != null ? value : ''),
    label: t(String(value != null ? value : '').toUpperCase()),
  });

  const editStandardOptions = () => {
    if (selectedStandardOption?.make) {
      setIsLoading(true);
      editStandardOption(selectedStandardOption)
        .then(() => {
          getStandardOptions();
          setSelectedStandardOption(undefined);
          snackbarSuccessMessage('Standard Option has been edited ;>D');
        })
        .catch(snackbarErrorHandler)
        .finally(() => setIsLoading(false));
    } else {
      snackbarErrorMessage('Fill in a make before editing :(');
    }
  };

  const deleteStandardOptions = () => {
    if (selectedStandardOption?.id) {
      setIsLoading(true);
      deleteStandardOption(selectedStandardOption.id)
        .then(() => {
          getStandardOptions();
          setSelectedStandardOption(undefined);
          snackbarSuccessMessage('Standard Option has been deleted ^^');
        })
        .catch(snackbarErrorHandler)
        .finally(() => setIsLoading(false));
    } else {
      snackbarErrorMessage('Fill in a make before editing :(');
    }
  };

  const addStandardOptions = () => {
    if (newStandardOption.make) {
      setIsLoading(true);
      createStandardOption(newStandardOption)
        .then(() => {
          getStandardOptions();
          setNewStandardOption(BASE_NEW_STANDARD_OPTION);
          snackbarSuccessMessage('Standard Option has been added :)');
        })
        .catch(snackbarErrorHandler)
        .finally(() => setIsLoading(false));
    } else {
      snackbarErrorMessage("Fill in a make before adding a standard option :'(");
    }
  };

  const getSelectProps = (propName: string) => ({
    ...(propName !== 'make' && { standardOption: selectedStandardOption || newStandardOption }),
    setValue: (option) =>
      selectedStandardOption
        ? setSelectedStandardOption({ ...selectedStandardOption, [propName]: option.value })
        : setNewStandardOption({ ...newStandardOption, [propName]: option.value }),
    value: carPropToOption(selectedStandardOption?.[propName] || newStandardOption[propName]),
  });

  return (
    <Box display={'flex'} flex={1} flexDirection={'column'} rowGap={'10px'}>
      <MakeSelect {...getSelectProps('make')} />
      <ModelSelect {...getSelectProps('model')} />
      <FuelSelect {...getSelectProps('fuel')} />
      <BodySelect {...getSelectProps('body')} />
      <HpSelect {...getSelectProps('hp')} />
      <BuildSelect {...getSelectProps('build')} />
      <Box display={'flex'} justifyContent={'flex-end'} flexDirection={'row'} columnGap={'10px'}>
        <ColoredButton color="warning" disabled={!selectedStandardOption || isLoading} onClick={deleteStandardOptions}>
          <DeleteForeverIcon />
        </ColoredButton>
        <ColoredButton disabled={!selectedStandardOption || isLoading} onClick={editStandardOptions}>
          Edit
        </ColoredButton>
        <ColoredButton disabled={!!selectedStandardOption || isLoading} onClick={addStandardOptions}>
          Add
        </ColoredButton>
      </Box>
    </Box>
  );
};
