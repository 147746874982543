export function urlParseBooleanWithNullify(val: any) {
  if (val === undefined || val === null) {
    return val;
  }
  // eslint-disable-next-line eqeqeq
  return val.toLowerCase() == 'true';
}

export function urlParseArray(val: any) {
      if( !val)
        return []

      if (Array.isArray(val)) {
        return val
      }
      return val.split(",");
}

export function urlParseArrayToString(val: any): string{
  if( !val)
    return val

  if (Array.isArray(val)) {
    return val.join(",")
  }
  return val
}

export function urlParseNumber(val: any) {
  if (val === undefined || val === null) {
    return val;
  }
  if (val !== '') {
    return Number(val);
  }
  return val;
}

export function urlParseJSON(val: any) {
  if (val === undefined || val === null) {
    return val;
  }
  if (val && val.length > 0) {
    try {
      return JSON.parse(val);
    } catch (err) {
      console.error(err);
      return null;
    }
  }
  return val;
}
