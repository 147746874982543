import React, { useCallback, useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Dialog, DialogContent, DialogTitle, IconButton, styled, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ColoredButton, LoadingWrapper, Tooltip } from '@japieglobal/shared';
import MessageIcon from '@mui/icons-material/Message';
import { ChatMessageBubble } from './chat-message-bubble';
import { useSnackbarErrorHandler } from '@japieglobal/shared/src/hooks';
import {
  createCsmDealerMessage,
  deleteCsmDealerMessage,
  editCsmDealerMessage,
  getCsmDealerMessages,
  Message,
  sendCsmMessageEmail,
} from '@japieglobal/shared/src/api/services';

const useStyles = makeStyles({
  dialogPaper: {
    minHeight: '80vh',
    maxHeight: '80vh',
  },
});

const CommentBoxForm = styled(DialogContent)({
  minHeight: '95px',
  maxHeight: '95px',
  display: 'flex',
  position: 'relative',
});

const StyledDialogTitle = styled(DialogTitle)({
  textAlign: 'center',
});

const CommentBoxText = styled(`input`)({
  width: '100%',
  border: '1px solid #dee1e2',
  padding: '20px 150px 20px 20px',
  maxWidth: '100%',
  maxHeight: '60px',
  height: 'auto !important',
  resize: 'none',
});

interface ActionsCellProps {
  dealerAccountName: string;
  messages?: Message[];
}

export const ActionsCell = ({ messages: initialMessages, dealerAccountName }: ActionsCellProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [isLoadingMessages, setIsLoadingMessages] = useState(false);
  const [messages, setMessages] = useState(initialMessages);
  const [showDialog, setShowDialog] = useState(false);
  const [newComment, setNewComment] = useState('');
  const [commentListContainer, setCommentListContainer] = useState<HTMLDivElement | undefined>(undefined);
  const { snackbarErrorHandler, snackbarSuccessMessage } = useSnackbarErrorHandler();

  const scrollToBottom = useCallback(() => {
    if (commentListContainer) {
      commentListContainer.scrollTop = commentListContainer.scrollHeight;
    }
  }, [commentListContainer]);

  const postComment = () => {
    if (newComment) {
      createCsmDealerMessage({ account_name: dealerAccountName, message: newComment })
        .then(() => {
          setNewComment('');
          snackbarSuccessMessage('Comment has been posted 👍');
          let url = window.location.href;
          if (!url.includes('dealer=')) {
            url = url + `&dealer=${dealerAccountName}`;
          }
          sendCsmMessageEmail({ url, comment: newComment, dealer: dealerAccountName });
          getMessages();
        })
        .catch(snackbarErrorHandler);
    }
  };

  const deleteComment = (commentId: number) => {
    if (commentId) {
      deleteCsmDealerMessage(commentId)
        .then(() => {
          setNewComment('');
          snackbarSuccessMessage('Comment has been deleted 🗑');
          getMessages();
        })
        .catch(snackbarErrorHandler);
    }
  };

  const editComment = (messageId: number, message: string) => {
    if (message && messageId) {
      editCsmDealerMessage(messageId, { account_name: dealerAccountName, message })
        .then(() => {
          setNewComment('');
          snackbarSuccessMessage('Comment has been edited 👍');
          getMessages();
        })
        .catch(snackbarErrorHandler);
    }
  };

  const getMessages = useCallback(() => {
    setIsLoadingMessages(true);
    getCsmDealerMessages(dealerAccountName)
      .then(setMessages)
      .catch(snackbarErrorHandler)
      .finally(() => setIsLoadingMessages(false));
  }, [dealerAccountName, snackbarErrorHandler]);

  useEffect(() => {
    if (showDialog === true) {
      getMessages();
    }
  }, [getMessages, showDialog]);

  useEffect(() => {
    if (commentListContainer && showDialog === true && messages && messages.length > 0) {
      scrollToBottom();
    }
  }, [commentListContainer, scrollToBottom, showDialog, messages]);

  return (
    <>
      {messages && messages?.length > 0 ? (
        <Tooltip
          onHoverContent={
            <div style={{ width: 700 }}>
              <ChatMessageBubble messages={[messages[messages?.length - 1]]} />
            </div>
          }
        >
          <IconButton onClick={() => setShowDialog(true)} color="primary" aria-label="open-messages">
            <MessageIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip onHoverContent={'No Messages'}>
          <IconButton onClick={() => setShowDialog(true)} color="primary" aria-label="open-messages">
            <MessageIcon />
          </IconButton>
        </Tooltip>
      )}
      <Dialog
        fullScreen={fullScreen}
        maxWidth="md"
        fullWidth
        onClose={() => setShowDialog(false)}
        aria-labelledby="export"
        open={showDialog}
        disableEnforceFocus
        classes={{ paper: classes.dialogPaper }}
      >
        <StyledDialogTitle>
          <div>{dealerAccountName}</div>
        </StyledDialogTitle>
        <DialogContent ref={setCommentListContainer} dividers style={{ minHeight: 'calc(80% - 64px)' }}>
          <LoadingWrapper isLoading={isLoadingMessages}>
            <ChatMessageBubble messages={messages} onDelete={deleteComment} onEdit={editComment} />
          </LoadingWrapper>
        </DialogContent>
        <CommentBoxForm dividers>
          <CommentBoxText
            value={newComment}
            onChange={(event) => setNewComment(event.target.value)}
            type="text"
            placeholder={t('WRITE_YOUR_MESSAGE_HERE')}
            onKeyPress={(event) => (event.key === 'Enter' ? postComment() : undefined)}
          />
          <ColoredButton type="submit" onClick={postComment}>
            {t('SEND')}
          </ColoredButton>
        </CommentBoxForm>
      </Dialog>
    </>
  );
};
