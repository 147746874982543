import { styled, TableBody, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CarResult, CarTaxes } from '@japieglobal/shared/src/types/car-types';
import { useDebouncedEffect } from '@japieglobal/shared/src/hooks';
import { getBpmComparison } from '@japieglobal/shared/src/api/services';
import { BpmResult } from '@japieglobal/shared/src/api/services';
import { Tooltip } from '@japieglobal/shared/src';
import { ColoredButtonSmall } from '../../cars/results/result-table-styles';
import { Table, TableCell } from '../../cars/results/car-popup-styles';
import { formatDecimalAsPercent } from '../../utils/number-format';
import { ResultTaxesChart } from './result-taxes-chart';

const Co2Button = styled(ColoredButtonSmall)({
  height: '30px',
  width: '40px',
});

const StyledDiv = styled(`div`)({
  flexFlow: 'row',
  marginLeft: '5px',
});

export const ResultTaxesCell = ({ car }: { car: CarResult }) => {
  const { t } = useTranslation();

  const calcPriceExEx = (tax: CarTaxes) => {
    return Math.round((car!.rank!.target_value! - tax.value) / 1.21);
  };

  const [bpmCars, setBpmCars] = useState<BpmResult[]>([]);
  const [showBpmAlert, setShowBpmAlert] = useState<boolean | undefined>(undefined);
  const MAX_BPM_DEVIATION = 3;
  const LOWEST_BPM_PERCENTILE = 0.05;

  const calcBpmAlert = (cars: BpmResult[]) => {
    if (!cars.length) return;
    const maxRatio = cars[cars.length - 1].bruto_bpm / car.taxes![0].data.value_init;
    const percentile =
      cars.findIndex((bpmCar) => bpmCar.license_plate === car.license_plate?.replaceAll('-', '')) / cars.length;
    const show = maxRatio > MAX_BPM_DEVIATION || percentile < LOWEST_BPM_PERCENTILE;
    setShowBpmAlert(show);
  };

  useDebouncedEffect(
    () => {
      if (car.taxes && car.license_plate)
        getBpmComparison(car.license_plate!).then((cars) => {
          setBpmCars(cars);
          calcBpmAlert(cars);
        });
    },
    200,
    [],
  );

  const getBpmColors = () => {
    const color = 'white';
    if (showBpmAlert) return { backgroundColor: 'warning.main', color: color };
    if (showBpmAlert === false) return { backgroundColor: 'primary.main', color: color };
    return {};
  };

  if (car.taxes) {
    return (
      <Tooltip
        interactive
        onHoverContent={
          <>
            <div style={{ paddingLeft: '5px', paddingRight: '5px', columnSpan: 'all' }}>{t('BPM')} *</div>
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              {car.taxes.map((tax: CarTaxes) => (
                <StyledDiv key={tax.type}>
                  <Table>
                    <TableBody>
                      <tr>
                        <TableCell>{t('SOURCE')}</TableCell>
                        <TableCell>{tax.source}</TableCell>
                      </tr>
                      <tr>
                        <TableCell>{t('TYPE')}</TableCell>
                        <TableCell>{tax.type.toUpperCase()}</TableCell>
                      </tr>
                      <tr>
                        <TableCell>{t('CO2')}</TableCell>
                        <TableCell>{tax.co2}</TableCell>
                      </tr>
                      <tr>
                        <TableCell>{t('GROSS_BPM')}</TableCell>
                        <TableCell>{tax.data.value_init}</TableCell>
                      </tr>
                      <tr>
                        <TableCell>{t('REST_BPM')}</TableCell>
                        <TableCell>{Math.round(tax.value)}</TableCell>
                      </tr>
                      <tr>
                        <TableCell>{t('WRITE_OFF')}</TableCell>
                        <TableCell>{formatDecimalAsPercent(tax.data.discount)}</TableCell>
                      </tr>
                      <tr>
                        <TableCell>{t('PRICE_EX_EX')}</TableCell>
                        <TableCell>{calcPriceExEx(tax)}</TableCell>
                      </tr>
                    </TableBody>
                  </Table>
                  <ResultTaxesChart car={car} bpmCars={bpmCars} />
                </StyledDiv>
              ))}
            </div>
            <div>
              <Typography style={{ fontSize: 9, width: 180 }}>* {t('BPM_INFO_MESSAGE')}</Typography>
            </div>
          </>
        }
      >
        <Co2Button sx={getBpmColors()} className="bpmButton">
          {t('BPM')}
        </Co2Button>
      </Tooltip>
    );
  }

  return null;
};
