import { styled, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import { CarResult } from '@japieglobal/shared/src/types/car-types';
import { ClicksPassedDown } from '../../states/click-states';
import { ResultTableRow } from '../results/result-table-row';
import { CarWindowPageCounter } from './car-window-page-counter';
import { CarWindowTable } from './car-window-table';
import { TopDealer } from '@japieglobal/shared/src/types';

const HeaderTable = styled(`table`)({ width: '100%' });

interface CarCompetitorViewProps {
  car: CarResult | undefined;
  cars: CarResult[];
  reorder: (source: number, destination: number) => void;
  removeRanking: () => void;
  currentPage: number;
  pageCount: number;
  changePage: (val: number) => void;
  reloadCarList: (id: number) => void;
  closeDialog?: () => void;
  clicks: ClicksPassedDown;
  taxationView?: boolean;
  topDealers?: TopDealer[];
  error?: string;
}

export function CarCompetitorView({
  car,
  cars,
  reorder,
  removeRanking,
  currentPage,
  changePage,
  pageCount,
  reloadCarList,
  clicks,
  taxationView = false,
  closeDialog = () => undefined,
  topDealers,
  error,
}: CarCompetitorViewProps) {
  const theme = useTheme();
  const showReduced = useMediaQuery(theme.breakpoints.down('sm'));

  return {
    main: car && (
      <>
        <HeaderTable>
          <tbody>
            <ResultTableRow
              showReduced={showReduced}
              closeDialog={closeDialog}
              car={car}
              reloadCarList={reloadCarList}
              isCompetitorIncluded
              taxationView={taxationView}
              windowView
              topDealers={topDealers}
            />
          </tbody>
        </HeaderTable>
      </>
    ),
    rows: car && (
      <CarWindowTable
        cars={cars}
        ownCar={car}
        clicks={clicks}
        reorder={reorder}
        removeRanking={removeRanking}
        taxationView={taxationView}
        error={error}
      />
    ),
    pagination: car && <CarWindowPageCounter changePage={changePage} pageCount={pageCount} currentPage={currentPage} />,
  };
}
