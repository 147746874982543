import React from 'react';
import { AppSearchParamKeys, NumberFilter } from '@japieglobal/shared/src';

export const TaxationFilterExtraCosts = () => {
  return (
    <NumberFilter
      min={0}
      max={1000000}
      labelTranslationKey="EXTRA_COSTS"
      searchParamKey={AppSearchParamKeys.EXTRA_COSTS}
      endAdornment={'€'}
      hasBackendDefault
    />
  );
};
