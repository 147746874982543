import { instance } from './instance';

export const sendMessage = async (body: { car_id: string; content: string }) =>
  instance.post(`/api/message`, body).then((res) => res.data);

export enum CarHeader {
  MODEL = 'model',
  MAKE = 'make',
  FUEL = 'fuel',
  GEAR = 'gear',
  BODY = 'body',
  HP = 'hp',
  MODEL_YEAR = 'model_year',
  BUILD = 'build',
  MILEAGE = 'mileage',
  OPTIONS = 'options',
}
export interface CarHeaderProps {
  type: CarHeader;
  fuel?: string;
  gear?: string;
  make?: string;
  model?: string;
  body?: string;
  build?: number;
  hp?: number;
  dealer_specific?: boolean; // backend default is true
  include_generic_commercial?: boolean; // backend default is false
}
export const carHeader = async (props: CarHeaderProps): Promise<{ headers: (string | number)[] }> =>
  instance.get('/api/headers', { params: props }).then((res) => res.data);
