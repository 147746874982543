import { AppSearchParamKeys, useAppSearchParams } from '@japieglobal/shared/src';
import { settings } from '@japieglobal/shared/src/settings';
import React from 'react';
import { TaxationFilterBody } from './taxation-filter-body-select';
import { TaxationFilterBuildYear } from './taxation-filter-build-year';
import { TaxationFilterFuel } from './taxation-filter-fuel-select';
import { TaxationFilterHorsePower } from './taxation-filter-horsepower';
import { TaxationFilterLicensePlate } from './taxation-filter-license-plate';
import { TaxationFilterMake } from './taxation-filter-make';
import { TaxationFilterMileage } from './taxation-filter-mileage';
import { TaxationFilterModel } from './taxation-filter-model';
import { TaxationFilterModelYear } from './taxation-filter-model-year';
import { TaxationFilterGear } from './taxation-filter-transmission-select';


export const TaxationTopFilters = () => {
  const [{ model_year }] = useAppSearchParams([AppSearchParamKeys.MODEL_YEAR]);
  return (
    <>
      {settings().country == 'nl' && <TaxationFilterLicensePlate />}
      <TaxationFilterMileage />
      <TaxationFilterMake />
      <TaxationFilterModel />
      <TaxationFilterFuel />
      <TaxationFilterGear />
      <TaxationFilterBody />
      <TaxationFilterBuildYear />
      <TaxationFilterHorsePower />
      {model_year ? <TaxationFilterModelYear /> : undefined}
    </>
  );
};
