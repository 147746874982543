import {AppSearchParamKeys, NumberFilter} from "@japieglobal/shared/src";
import React from "react";

export const TaxationFilterMaxIterations = () => (
    <NumberFilter
        min={0}
        max={100}
        labelTranslationKey="MAX_ITERATIONS"
        searchParamKey={AppSearchParamKeys.MAX_ITERATIONS}
    />
);