import React from 'react';
import { CarHeader, CarInformationResult } from '@japieglobal/shared/src/api/services';
import { Grid } from '@mui/material';
import { noop } from '@japieglobal/shared/src/utils';
import { TaxationStep } from './taxation-steps/taxation-step';
import { TaxationFilterTaxationButton } from './left-side-filters/taxation-filter-taxation-button';
import { TaxationErrorView } from './taxation-list-view/taxation-error-view';
import { WindowResultErrorCode } from '@japieglobal/shared/src/types';

interface TaxationOptionsView {
  evaluateAllCars: () => void;
  carInformation?: CarInformationResult;
}

export const TaxationOptionsView = ({ evaluateAllCars, carInformation }: TaxationOptionsView) =>
  carInformation?.error_code || carInformation?.error_message ? (
    <TaxationErrorView
      windowResult={{
        pagable: { page_count: 0, page_index: 0, page_size: 0, total: 0 },
        window: [],
        error: WindowResultErrorCode.ERROR_INVALID_CAR,
      }}
    />
  ) : (
    <Grid padding={1} container rowSpacing={1}>
      <Grid item>
        <TaxationStep goToNextStep={noop} inputKey={CarHeader.OPTIONS} />
      </Grid>
      <Grid container item justifyContent={'flex-end'}>
        <TaxationFilterTaxationButton key="taxation" onSubmit={evaluateAllCars} />
      </Grid>
    </Grid>
  );
