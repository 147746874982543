import { TableBody } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Table, TableCell } from '../../../cars/results/car-popup-styles';
import { Tooltip } from '@japieglobal/shared/src/components';
import { formatThousands } from '../../../utils/number-format';

interface TopdownHoverProps {
  value: number | undefined;
  topdown_value: number | undefined;
  topdown_value_breakdown: Record<string, number> | undefined;
  table_header?: string;
  value_label: string;
  topdown_value_label: string;
}
export const TopdownHover = ({
  value,
  topdown_value,
  topdown_value_breakdown,
  table_header,
  value_label,
  topdown_value_label,
}: TopdownHoverProps) => {
  const { t } = useTranslation();

  const topdownValueBreakDownRows = Object.entries(topdown_value_breakdown || {}).map((entry) => (
    <tr key={entry[0]}>
      <TableCell>{t(entry[0].toUpperCase())}</TableCell>
      <TableCell>-{entry[1]}</TableCell>
    </tr>
  ));

  return (
    <Tooltip
      onHoverContent={
        <>
          <div>{table_header}</div>
          <Table>
            <TableBody>
              <tr>
                <TableCell>{value_label}</TableCell>
                <TableCell>{value}</TableCell>
              </tr>
              {topdownValueBreakDownRows}
              <tr>
                <TableCell style={{ borderTop: '2px solid black' }}>{topdown_value_label}</TableCell>
                <TableCell style={{ borderTop: '2px solid black' }}>{topdown_value}</TableCell>
              </tr>
            </TableBody>
          </Table>
        </>
      }
    >
      <span>
        {topdown_value_label}: € {formatThousands(topdown_value)}
      </span>
    </Tooltip>
  );
};
