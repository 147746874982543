import React, { useMemo } from 'react';
import { SelectFilter, AppSearchParamKeys, useAppSearchParams } from '@japieglobal/shared/src/components';
import { Grid } from '@mui/material';
import { CarHeader, CarHeaderProps } from '@japieglobal/shared/src/api/services';

export const TaxationFilterFuel = () => {
  const [{ make, model }] = useAppSearchParams([AppSearchParamKeys.MAKE, AppSearchParamKeys.MODEL]);
  const carHeaderOptions: CarHeaderProps = useMemo(
    () => ({ dealer_specific: false, type: CarHeader.FUEL, make, model }),
    [make, model],
  );
  const filterDependencies = useMemo(
    () => ({ [AppSearchParamKeys.MAKE]: make, [AppSearchParamKeys.MODEL]: model }),
    [make, model],
  );
  return (
    <Grid item xs={2}>
      <SelectFilter
        tabOnEnter
        hideResetButton
        searchParamKey={AppSearchParamKeys.FUEL}
        carHeaderOptions={carHeaderOptions}
        filterDependencies={filterDependencies}
        labelTranslationKey="FUEL"
      />
    </Grid>
  );
};
