import React, { PropsWithChildren } from 'react';
import { Box, Typography } from '@mui/material';
import { CarResult } from '@japieglobal/shared/src/types/car-types';
import { formatThousands } from '../../../utils/number-format';
import { useTranslation } from 'react-i18next';
import { ColoredButton } from '@japieglobal/shared/src/components';

const Price = ({ children }: PropsWithChildren) => (
  <Typography sx={{ fontSize: 12, textAlign: 'center' }}>{children}</Typography>
);

export const CarWindowPriceCell = React.memo(
  ({ car, removeRanking }: { car: CarResult; removeRanking: () => void }) => {
    const { t } = useTranslation();

    return (
      <>
        {car.rank?.target_value && car.price?.recalculate && car.price?.recalculate.length > 0 ? (
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Price>{`€ ${formatThousands(car.rank?.target_value)}`}</Price>
            <Price>{`${car.price.recalculate}`}</Price>
            <ColoredButton
              style={{
                height: '20px',
                fontSize: '10px',
              }}
              type="button"
              onClick={removeRanking}
            >
              {t('CANCEL')}
            </ColoredButton>
          </Box>
        ) : (
          <>{`€ ${formatThousands(car.price?.local || car.rank?.target_value)}`}</>
        )}
      </>
    );
  },
);
