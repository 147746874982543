import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import PeopleIcon from '@mui/icons-material/People';
import StorageIcon from '@mui/icons-material/Storage';
import React, { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { AdminMenuButton } from './admin-menu-button';
import { AdminPageType } from '../hooks/admin-query-state';
import { UserRoles } from '@japieglobal/shared/src/api/services';
import { UserContext } from '@japieglobal/shared/src/user-context';

interface AdminMenuProps {
  setPageTypeProxy: (pageType: AdminPageType) => void;
  pageType: AdminPageType;
}

export const AdminMenu = React.memo(({ setPageTypeProxy, pageType }: AdminMenuProps) => {
  const { user } = useContext(UserContext);

  const isSuperAdmin = user.role === UserRoles.SUPER_ADMIN;
  const isAdmin = [UserRoles.SUPER_ADMIN, UserRoles.ADMIN].includes(user.role);
  const isScraperManager = user.role === UserRoles.SCRAPE_USER && user.permissions.includes('scrapeManagement');
  const hasUserActivityPermission = user.permissions.includes('userActivity');

  const { t } = useTranslation();
  const navigate = useNavigate();

  const onClickSuperAdmins = useCallback(() => {
    setPageTypeProxy(AdminPageType.SUPER_ADMINS);
  }, [setPageTypeProxy]);
  const onClickAdmins = useCallback(() => {
    setPageTypeProxy(AdminPageType.ADMINS);
  }, [setPageTypeProxy]);
  const onClickUsers = useCallback(() => {
    setPageTypeProxy(AdminPageType.USERS);
  }, [setPageTypeProxy]);
  const onClickServiceAccounts = useCallback(() => {
    setPageTypeProxy(AdminPageType.SERVICE_ACCOUNTS);
  }, [setPageTypeProxy]);
  const onClickActivityPurchaseView = useCallback(() => {
    setPageTypeProxy(AdminPageType.ACTIVITY_PURCHASE_VIEW);
  }, [setPageTypeProxy]);
  const onClickKeyUsers = useCallback(() => {
    setPageTypeProxy(AdminPageType.KEY_USERS);
  }, [setPageTypeProxy]);
  const onClickGlobalActivityStats = useCallback(() => {
    setPageTypeProxy(AdminPageType.GLOBAL_ACTIVITY_LIST);
    navigate(window.location.pathname, { replace: true });
  }, [setPageTypeProxy, navigate]);
  const onClickCsmReport = useCallback(() => {
    setPageTypeProxy(AdminPageType.CSM_REPORT);
  }, [setPageTypeProxy]);
  const onClickScrapeAdminUsers = useCallback(() => {
    setPageTypeProxy(AdminPageType.SCRAPE_ADMIN_USERS);
  }, [setPageTypeProxy]);
  const onClickDealers = useCallback(() => {
    setPageTypeProxy(AdminPageType.DEALERS);
  }, [setPageTypeProxy]);

  return (
    <>
      {isSuperAdmin && (
        <AdminMenuButton
          startIcon={<PeopleIcon />}
          text={t('SUPER_ADMINS')}
          onClick={onClickSuperAdmins}
          isActive={pageType === AdminPageType.SUPER_ADMINS}
        />
      )}
      {isSuperAdmin && (
        <AdminMenuButton
          startIcon={<PeopleIcon />}
          text={t('ADMINISTRATOR')}
          onClick={onClickAdmins}
          isActive={pageType === AdminPageType.ADMINS}
        />
      )}
      {isAdmin && (
        <AdminMenuButton
          startIcon={<PeopleIcon />}
          text={t('USERS')}
          onClick={onClickUsers}
          isActive={pageType === AdminPageType.USERS}
        />
      )}
      {isAdmin && (
        <AdminMenuButton
          startIcon={<PeopleIcon />}
          text={t('SERVICE_ACCOUNTS')}
          onClick={onClickServiceAccounts}
          isActive={pageType === AdminPageType.SERVICE_ACCOUNTS}
        />
      )}
      {isSuperAdmin && (
        <AdminMenuButton
          startIcon={<PeopleIcon />}
          text={t('ACTIVITY_PURCHASE_VIEW')}
          onClick={onClickActivityPurchaseView}
          isActive={pageType === AdminPageType.ACTIVITY_PURCHASE_VIEW}
        />
      )}
      {isSuperAdmin && (
        <AdminMenuButton
          startIcon={<PeopleIcon />}
          text={t('KEY_USERS')}
          onClick={onClickKeyUsers}
          isActive={pageType === AdminPageType.KEY_USERS}
        />
      )}
      {(isSuperAdmin || isScraperManager) && (
        <AdminMenuButton
          startIcon={<PeopleIcon />}
          text={t('SCRAPE_ADMIN_USERS')}
          onClick={onClickScrapeAdminUsers}
          isActive={pageType === AdminPageType.SCRAPE_ADMIN_USERS}
        />
      )}
      {isSuperAdmin && (
        <AdminMenuButton
          startIcon={<DirectionsCarIcon />}
          text={t('DEALERS')}
          onClick={onClickDealers}
          isActive={pageType === AdminPageType.DEALERS}
        />
      )}
      {hasUserActivityPermission && (
        <AdminMenuButton
          startIcon={<StorageIcon />}
          text={t('GLOBAL_ACTIVITY_STATS')}
          onClick={onClickGlobalActivityStats}
          isActive={pageType === AdminPageType.GLOBAL_ACTIVITY_LIST}
        />
      )}
      {isSuperAdmin && (
        <AdminMenuButton
          startIcon={<StorageIcon />}
          text={t('CSM_REPORT')}
          onClick={onClickCsmReport}
          isActive={pageType === AdminPageType.CSM_REPORT}
        />
      )}
    </>
  );
});
