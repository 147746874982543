import React, { useMemo } from 'react';
import { SelectFilter, AppSearchParamKeys, useAppSearchParams } from '@japieglobal/shared/src/components';
import { Grid } from '@mui/material';
import { CarHeader, CarHeaderProps } from '@japieglobal/shared/src/api/services';

export const TaxationFilterHorsePower = () => {
  const [{ make, model, fuel, gear, body, build }] = useAppSearchParams();
  const carHeaderOptions: CarHeaderProps = useMemo(
    () => ({
      dealer_specific: false,
      type: CarHeader.HP,
      make,
      model,
      fuel,
      gear,
      body,
      build,
    }),
    [make, model, fuel, gear, body, build],
  );
  const filterDependencies = useMemo(
    () => ({
      [AppSearchParamKeys.MAKE]: make,
      [AppSearchParamKeys.MODEL]: model,
      [AppSearchParamKeys.FUEL]: fuel,
      [AppSearchParamKeys.GEAR]: gear,
      [AppSearchParamKeys.BODY]: body,
      [AppSearchParamKeys.BUILD]: build,
    }),
    [body, fuel, gear, make, model, build],
  );
  return (
    <Grid item xs={2}>
      <SelectFilter
        hideResetButton
        searchParamKey={AppSearchParamKeys.HP}
        carHeaderOptions={carHeaderOptions}
        filterDependencies={filterDependencies}
        labelTranslationKey="HP"
      />
    </Grid>
  );
};
