import { Tooltip } from '@japieglobal/shared/src/components';
import { CarResult } from '@japieglobal/shared/src/types/car-types';
import { Badge } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { CarScrapeAdminButton } from '../../car-scrape-admin-button';


export const CarWindowImageCell = React.memo(
  ({ car, ownCar, taxationView }: { ownCar: CarResult; car: CarResult; taxationView: boolean }) => {
    const [largeImagePosition, setLargeImagePosition] = useState<number | undefined>(undefined);

    const imageRef = useRef<HTMLImageElement | null>(null);

    useEffect(() => {
      if (imageRef.current) {
        const imageHeight = imageRef.current.height;
        if (imageHeight > 68) {
          setLargeImagePosition(Math.round(((imageHeight - 68) / imageHeight) * 100));
        }
      }
    }, []);

    return (
      <Tooltip
        onHoverContent={
          <>
            <img width="430px" alt="car large" src={car.image} />
            {!taxationView && <img alt="car large" src={ownCar.image} />}
          </>
        }
      >
        <Badge
          badgeContent={car.rank?.counter}
          color={car.id === ownCar.id ? 'warning' : 'primary'}
          overlap="circular"
          sx={{ '& .MuiBadge-badge': { mr: '4px', top: largeImagePosition } }}
          max={9999}
        >
          <img ref={imageRef} style={{ maxWidth: '100%' }} alt="car" src={car.image} />
          <CarScrapeAdminButton car={car} marginBottom={largeImagePosition || 0} />
        </Badge>
      </Tooltip>
    );
  },
);
