import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { styled } from '@mui/system';
import { makeStyles } from '@mui/styles';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { dateDifference } from '@japieglobal/shared/src/utils';
import { useSnackbarErrorHandler } from '@japieglobal/shared/src/hooks';
import { useSearchParams } from 'react-router-dom';
import {
  AppSearchParamKeys,
  ConfirmationDialog,
  ToggleFilter,
  Tooltip,
  useAppSearchParams,
} from '@japieglobal/shared/src';
import { Colors } from '@japieglobal/shared/src/style/palettes/theme-colors';
import { createPriceProposal, patchVisibilityCar } from '@japieglobal/shared/src/api/services';
import { CarResult, getCarPrice } from '@japieglobal/shared/src/types';
import { BoxFlexColumn, CarTableCell } from '../result-table-styles';
import { CarClickChartTiny } from '../../turnover-chart/car-click-chart-tiny';
import { UserContext } from '@japieglobal/shared/src/user-context';

const VisibilityIconStyled = styled(VisibilityIcon)({
  height: '10px',
  width: '10px',
});

const VisibilityOffIconStyled = styled(VisibilityOffIcon)({
  height: '10px',
  width: '10px',
});

const StyledButton = styled(`button`)(({ theme }) => ({
  width: '25px',
  position: 'relative',
  height: '15px',
  borderRadius: '0',
  fontSize: '9px',
  paddingLeft: '2px',
  paddingRight: '2px',
  backgroundColor: 'white',
  border: `1px solid ${theme.palette.background.default}`,
  color: '#333',
  marginRight: '1px',
  cursor: 'pointer',
}));

const styles = makeStyles({
  buttonActive: {
    '&::after': {
      content: '""',
      position: 'absolute',
      top: '2px',
      right: '2px',
      width: '5px',
      height: '5px',
    },
  },
  buttonOutlined: {
    outline: '1px dotted #485A68',
  },
  buttonGreenAfter: {
    '&::after': {
      backgroundColor: '#6CAF51',
    },
  },
  buttonRedAfter: {
    '&::after': {
      backgroundColor: '#E54D42',
    },
  },
  buttonOrangeAfter: {
    '&::after': {
      backgroundColor: '#E47D31',
    },
  },
  button: {
    'width': '30px',
    'minWidth': '30px',
    'maxWidth': '30px',
    'position': 'relative',
    'height': '15px',
    'borderRadius': '0',
    'fontSize': '8px',
    'paddingLeft': '2px',
    'paddingRight': '2px',
    'backgroundColor': Colors.WHITE,
    'color': '#333',
    'marginRight': '1px',
    'cursor': 'pointer',
    '&:hover': {
      backgroundColor: Colors.GREY_200,
      color: '#333',
    },
  },

  buttonWide: {
    minWidth: '35px',
    maxWidth: '35px',
  },
});

const PriceInputWrapper = styled(`div`)({
  width: '100px',
  position: 'relative',
});

const PriceInputEuroIcon = styled(`div`)({
  position: 'absolute',
  left: '12px',
  transform: 'translateY(-50%)',
  top: '50%',
});

const PriceInputElement = styled(`input`)(({ theme }) => ({
  textAlign: 'center',
  width: '100%',
  height: '3em',
  paddingLeft: '12px',
  border: `1px solid ${theme.palette.background.default}`,
}));

function EuroIconInput({
  value,
  setValue,
  onPriceInputEnter,
  disabled,
}: {
  disabled?: boolean;
  value: string;
  setValue: (val: string) => void;
  onPriceInputEnter: (val: number) => void;
}) {
  const onKeyPress = useCallback(
    (e) => (e.key === 'Enter' ? onPriceInputEnter(Number(value)) : undefined),
    [onPriceInputEnter, value],
  );
  const onChange = useCallback((e) => setValue(e.target.value), [setValue]);
  return (
    <PriceInputWrapper>
      <PriceInputEuroIcon>€</PriceInputEuroIcon>
      <PriceInputElement
        disabled={disabled}
        type="number"
        size={5}
        onKeyPress={onKeyPress}
        value={value}
        onChange={onChange}
      />
    </PriceInputWrapper>
  );
}

interface VisibilityButton {
  reloadCarList: (id: number) => void;
  closeDialog: () => void;
  car: CarResult;
}
const VisibilityButton = ({ car, reloadCarList, closeDialog }: VisibilityButton) => {
  const { t } = useTranslation();
  const { snackbarErrorHandler } = useSnackbarErrorHandler();

  const [dialogVisible, setDialogVisible] = useState<boolean>(false);

  const showDialog = useCallback(() => {
    setDialogVisible(true);
  }, []);
  const hideDialog = useCallback(() => {
    setDialogVisible(false);
  }, []);

  const question = useMemo(() => {
    if (car.hidden) {
      return t('SHOW_CAR_QUESTION');
    }
    return t('HIDE_CAR_QUESTION');
  }, [car.hidden, t]);

  const dialogYesAction = useCallback(() => {
    patchVisibilityCar({
      id: String(car.id),
      hidden: !car.hidden,
      messageContent: `${t('VISIBILITY_CHANGE')}: ${t(!car.hidden ? 'HIDDEN' : 'SHOWN')}`,
    })
      .catch(snackbarErrorHandler)
      .finally(() => {
        setDialogVisible(false);
        reloadCarList(car.id!);
        closeDialog();
      });
  }, [car.id, car.hidden, t, snackbarErrorHandler, reloadCarList, closeDialog]);

  return (
    <>
      <Tooltip onHoverContent={t('HIDE_CAR_TOOLTIP')}>
        <StyledButton type="button" onClick={showDialog} style={{ width: 'auto' }} className="eyeButton">
          {car.hidden === true ? <VisibilityOffIconStyled /> : <VisibilityIconStyled />}
        </StyledButton>
      </Tooltip>
      {dialogVisible && (
        <ConfirmationDialog
          closeDialog={hideDialog}
          title={t('VISIBILITY_CAR_TITLE')}
          question={question}
          yesAction={dialogYesAction}
          noAction={hideDialog}
        />
      )}
    </>
  );
};

interface ResultPriceCellProps {
  car: CarResult;
  price: string;
  setPrice: (val: string) => void;
  closeDialog: () => void;
  reloadCarList: (id: number) => void;
  competitorView?: boolean;
}
export const ResultPriceCell = React.memo(
  ({ car, competitorView, closeDialog, reloadCarList, price, setPrice }: ResultPriceCellProps) => {
    const classes = styles();
    const { user } = useContext(UserContext);

    const hasRightForPriceProposal = useMemo(() => user.permissions.includes('priceProposal'), [user.permissions]);
    const { snackbarErrorHandler } = useSnackbarErrorHandler();
    const [inputPriceDiscount, setInputPriceDiscount] = useState<number | undefined>(undefined);
    const [showEnterChoiceDialog, setEnterChoiceDialog] = useState<boolean>(false);
    const hideEnterChoiceDialog = useCallback(() => {
      setEnterChoiceDialog(false);
    }, []);

    const [, setSearchParams] = useSearchParams();
    const [{ priceDiscount }] = useAppSearchParams([AppSearchParamKeys.PRICE_DISCOUNT]);

    const setPriceDiscount = useCallback(
      (value) => {
        setSearchParams((prev) => {
          prev.set('priceDiscount', String(value));
          return prev;
        });
      },
      [setSearchParams],
    );

    useEffect(() => {
      if (priceDiscount && competitorView) {
        setPrice(String(priceDiscount));
      } else {
        setPrice(String(getCarPrice(car)));
      }
    }, [car, competitorView, priceDiscount, setPrice]);

    const setWindowCarId = useCallback(() => {
      setSearchParams((prev) => {
        prev.set('window_car_id', String(car.id));
        return prev;
      });
    }, [car.id, setSearchParams]);

    const dialogActionSetPriceDiscount = useCallback(() => {
      if (inputPriceDiscount) {
        setPriceDiscount(inputPriceDiscount);
        setWindowCarId();
      }
    }, [inputPriceDiscount, setWindowCarId, setPriceDiscount]);

    const dialogActionCreatePriceProposal = useCallback(() => {
      if (inputPriceDiscount) {
        createPriceProposal({
          body: { rank_current_value: car.price?.local, rank_target_value: inputPriceDiscount },
          id: car.id!,
        })
          .then(() => {
            reloadCarList(car.id!);
          })
          .catch(snackbarErrorHandler);
      }
    }, [car, inputPriceDiscount, reloadCarList, snackbarErrorHandler]);

    const onPriceInputEnter = useCallback(
      (val: number) => {
        if (val) {
          if (car.proposal || !hasRightForPriceProposal || competitorView === true) {
            setPriceDiscount(val);
            setWindowCarId();
          } else {
            setInputPriceDiscount(val);
            setEnterChoiceDialog(true);
          }
        }
      },
      [car.proposal, competitorView, hasRightForPriceProposal, setWindowCarId, setPriceDiscount],
    );

    const { t } = useTranslation();

    return (
      <CarTableCell width={130}>
        <BoxFlexColumn>
          <EuroIconInput
            disabled={car.has_price_proposal}
            setValue={setPrice}
            value={price}
            onPriceInputEnter={onPriceInputEnter}
          />
          <div style={{ fontSize: 11, marginTop: 5 }}>
            {(car.stock_days_client != null || car.stock_date_client != null) && (
              <Tooltip onHoverContent={t('STOCK_DAYS_CLIENT')}>
                <ToggleFilter
                  className={`${classes.button}`}
                  searchParamKey={AppSearchParamKeys.SORT_STOCK_DAYS_CLIENT}
                  labelTranslationKey={`D: ${
                    car.stock_date_client
                      ? dateDifference('d', new Date(car.stock_date_client), new Date())
                      : car.stock_days_client
                  }`}
                  hideResetButton
                />
              </Tooltip>
            )}
            <Tooltip onHoverContent={t('STAND_TIME')}>
              <ToggleFilter
                className={`${classes.button} ${classes.buttonWide} stockDaysButton`}
                searchParamKey={AppSearchParamKeys.SORT_STOCK_DAYS}
                labelTranslationKey={`S: ${car.stock_days}`}
                hideResetButton
              />
            </Tooltip>

            <Tooltip
              onHoverContent={
                <>
                  {t('CLICKS_LAST_WEEK')}
                  <div>
                    <CarClickChartTiny car={car} />
                  </div>
                </>
              }
            >
              <ToggleFilter
                className={`${classes.button} clicksButton`}
                searchParamKey={AppSearchParamKeys.SORT_CLICKS}
                labelTranslationKey={`C: ${car.stat?.clicks}`}
                hideResetButton
              />
            </Tooltip>

            {!!car.stat?.leads && (
              <Tooltip onHoverContent={t('TOTAL_LEADS_ON_CAR')}>
                <ToggleFilter
                  className={classes.button}
                  searchParamKey={AppSearchParamKeys.SORT_LEADS}
                  labelTranslationKey={`L: ${car.stat?.leads}`}
                  hideResetButton
                />
              </Tooltip>
            )}
            <VisibilityButton reloadCarList={reloadCarList} closeDialog={closeDialog} car={car} />
            {showEnterChoiceDialog && (
              <ConfirmationDialog
                closeDialog={hideEnterChoiceDialog}
                title={t('CREATE_PRICE_PROPOSAL_OR_SHOW_WINDOW')}
                question={t('ARE_YOU_SURE_TO_CREATE_PRICE_PROPOSAL')}
                textYes={t('CREATE_PRICE_PROPOSAL')}
                textNo={t('SHOW_WINDOW')}
                yesAction={dialogActionCreatePriceProposal}
                noAction={dialogActionSetPriceDiscount}
              />
            )}
          </div>
        </BoxFlexColumn>
      </CarTableCell>
    );
  },
);
