import { PageQuery, Pageable } from '../../types/pagination';
import { instance } from './instance';

export interface BannedCarResult {
  price_local: number;
  license_plate: number;
  image: string;
  make: string;
  model: string;
  reason?: string;
  url: string;
  id: number;
  window_car_id?: number;
  ban_user_id?: string;
  ban_date: string;
  taxation_url: string;
}

export interface BannedCarsResult {
  results: BannedCarResult[];
  pagable: Pageable;
}

export const listBannedCars = async (params: PageQuery): Promise<BannedCarsResult> =>
  instance.get('/api/ban-car/list', { params }).then((res) => res.data);

export const banCar = async (carId: number, reason: string, windowCarId?: number, taxationUrl?: string) =>
  instance.post(`/api/ban-car/${carId}`, { reason, window_car_id: windowCarId, taxation_url: taxationUrl });

export const unBanCar = async (carId: number) => instance.post(`/api/unban-car/${carId}`);
