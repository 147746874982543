import React, { FormEvent, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { PricingType } from '@japieglobal/shared/src/types';
import {
  CarInformationResult,
  CarPricingQuery,
  CarWindowResult,
  getCarInformation,
  priceCarByCarProperties,
} from '@japieglobal/shared/src/api/services';
import { TaxationFilterTaxationButton } from './left-side-filters/taxation-filter-taxation-button';
import { TaxationFilter } from './taxation-filter';
import { AppSearchParamKeys, PageContainer, useAppSearchParams } from '@japieglobal/shared';
import { useClickQueryStates } from '../states/click-states';
import { useSnackbarErrorHandler } from '@japieglobal/shared/src/hooks';
import { TaxationTopView } from './taxation-top-view/taxation-top-view';
import { settings } from '@japieglobal/shared/src/settings';
import { UserContext } from '@japieglobal/shared/src/user-context';
import { useDebouncedEffect } from '../../../shared/src/hooks';
import { TaxationMainView } from './taxation-main-view';
import { ClickState } from '@japieglobal/shared/src/types/click-state';

export const TaxationPage = () => {
  const currentCountry = useMemo(() => settings().country, []);
  const { snackbarErrorHandler } = useSnackbarErrorHandler();
  const { clicksAPIStyle } = useClickQueryStates();
  const [searchParams, setSearchParams] = useAppSearchParams();
  const {
    model,
    fuel,
    make,
    body,
    build,
    hp,
    gear,
    license_plate,
    mileage,
    options,
    model_year,
    equipment_four_doors,
    extra_costs,
    extra_margin,
    maturity_months,
    mileage_at_maturity,
    search_value,
    stock_days,
    location_names,
    max_iterations,
    start_price,
    scope,
  } = searchParams;
  const [selectedCountry, setSelectedCountry] = useState<string>(currentCountry);
  const [carInformation, setCarInformation] = useState<CarInformationResult>();
  const [isLoadingCarInformation, setIsLoadingCarInformation] = useState<boolean>(false);
  const { user } = useContext(UserContext);

  const carInformationIsFilled = useMemo(
    () =>
      (model !== undefined &&
        fuel !== undefined &&
        make !== undefined &&
        body !== undefined &&
        build !== undefined &&
        hp !== undefined &&
        gear !== undefined) ||
      license_plate !== undefined,
    [body, build, fuel, gear, hp, license_plate, make, model],
  );

  const availableCountries = useMemo(
    () =>
      [currentCountry].concat(
        user.permissions
          .filter((permission) => permission.includes('internationalTrader_'))
          .map((countryPermission) => countryPermission.split('internationalTrader_')[1]),
      ),
    [currentCountry, user.permissions],
  );
  const [windowResults, setWindowResults] = useState<{ [country: string]: CarWindowResult | undefined }>(
    Object.fromEntries(availableCountries.map((v) => [v, undefined])),
  );
  const [windowIsLoading, setWindowIsLoading] = useState<{ [country: string]: boolean }>(
    Object.fromEntries(availableCountries.map((v) => [v, false])),
  );
  const [clickDealers, setClickDealers] = useState<{ [country: string]: string | undefined }>(
    Object.fromEntries(availableCountries.map((v) => [v, undefined])),
  );
  const licensePlateMemo = useMemo(() => license_plate, [license_plate]);
  const prevLicensePlate = useRef<string>();

  const resetAction = useCallback(() => {
    setSearchParams(
      prevLicensePlate.current && prevLicensePlate.current !== licensePlateMemo && licensePlateMemo
        ? { [AppSearchParamKeys.LICENSE_PLATE]: licensePlateMemo }
        : {},
      true,
    );
    setCarInformation(undefined);
    setWindowResults(Object.fromEntries(availableCountries.map((v) => [v, undefined])));
    setClickDealers(Object.fromEntries(availableCountries.map((v) => [v, undefined])));
  }, [availableCountries, licensePlateMemo, setSearchParams]);

  const evaluateCar = useCallback(
    (country: string, pageIndex?: number, pricingTarget?: number) => {
      if (carInformationIsFilled) {
        setWindowIsLoading((prev) => ({ ...prev, [country]: true }));

        const queryProps: CarPricingQuery = {
          ...searchParams,
          ...clicksAPIStyle,
          page_size: 10,
          page_index: pageIndex ?? 0,
          target_country: country,
        };
        if (pricingTarget !== undefined) {
          queryProps.pricing_target = pricingTarget;
          queryProps.pricing_type = PricingType.POSITION;
        }

        queryProps.click_dealer = clickDealers[country];
        if (clickDealers[selectedCountry] && queryProps.click_equipment_in_stock === undefined) {
          queryProps.click_equipment_in_stock = ClickState.ALL;
        }

        priceCarByCarProperties(queryProps)
          .then(async (res) => {
            setWindowResults((prev) => ({ ...prev, [country]: res }));
            if (res.car && res.car.country?.toLowerCase() === settings().country) {
              const { make, model, gear, fuel, body, build, hp, model_year, options, equipment, mileage } = res.car;
              setSearchParams({
                mileage,
                make,
                model,
                gear,
                fuel,
                body,
                build,
                hp,
                model_year,
                equipment_four_doors: equipment?.four_doors,
                options: options?.split(',') || undefined,
              });
            }
          })
          .catch(snackbarErrorHandler)
          .finally(() => setWindowIsLoading((prev) => ({ ...prev, [country]: false })));
      }
    },
    [
      carInformationIsFilled,
      clickDealers,
      clicksAPIStyle,
      searchParams,
      selectedCountry,
      setSearchParams,
      snackbarErrorHandler,
    ],
  );

  const evaluateAllCars = useCallback(
    (event?: FormEvent) => {
      event?.preventDefault();
      availableCountries.map((country) => evaluateCar(country));
    },
    [availableCountries, evaluateCar],
  );

  useEffect(() => {
    if (prevLicensePlate.current && prevLicensePlate.current !== licensePlateMemo && licensePlateMemo) {
      resetAction();
    }
    prevLicensePlate.current = licensePlateMemo;
  }, [licensePlateMemo, resetAction]);

  useEffect(() => {
    if (licensePlateMemo && !carInformation) {
      if (license_plate === 'NB' || Number(license_plate)) return;
      setIsLoadingCarInformation(true);
      getCarInformation({ licensePlate: licensePlateMemo, options: (options || []).join(',') })
        .then((res) => {
          const { make, model, gear, fuel, body, build, hp, model_year, options, equipment_four_doors } = res;
          setSearchParams({
            make,
            model,
            gear,
            fuel,
            body,
            build,
            hp,
            model_year,
            equipment_four_doors: equipment_four_doors,
            options: options?.split(',') || undefined,
          });
          setCarInformation(res);
        })
        .catch(snackbarErrorHandler)
        .finally(() => setIsLoadingCarInformation(false));
    }
  }, [carInformation, mileage, licensePlateMemo, setSearchParams, snackbarErrorHandler, license_plate, options]);

  useEffect(() => {
    if (body && build && fuel && gear && hp && make && model && !carInformation) {
      getCarInformation({
        body,
        build,
        fuel,
        gear,
        hp,
        make,
        model,
        options: (options || []).join(','),
        model_year,
        equipment_four_doors,
      })
        .then((res) => {
          const { model_year, options, equipment_four_doors } = res;
          setSearchParams({
            model_year,
            equipment_four_doors: equipment_four_doors,
            options: options?.split(',') || undefined,
          });
          setCarInformation(res);
        })
        .catch(snackbarErrorHandler)
        .finally(() => setIsLoadingCarInformation(false));
    }
  }, [
    body,
    build,
    fuel,
    gear,
    hp,
    make,
    model,
    setSearchParams,
    carInformation,
    snackbarErrorHandler,
    options,
    model_year,
    equipment_four_doors,
  ]);

  useEffect(() => {
    if (!carInformationIsFilled && !!carInformation) {
      setCarInformation(undefined);
    }
  }, [carInformationIsFilled, carInformation]);

  useDebouncedEffect(
    () => {
      evaluateAllCars();
    },
    500,
    [
      extra_costs,
      extra_margin,
      maturity_months,
      mileage_at_maturity,
      search_value,
      stock_days,
      location_names,
      max_iterations,
      start_price,
      scope,
    ],
  );
  useDebouncedEffect(
    () => {
      evaluateAllCars();
    },
    500,
    [clicksAPIStyle],
  );

  return (
    <PageContainer
      internal
      leftMenuExtraButtons={[<TaxationFilterTaxationButton key="taxation" onSubmit={evaluateAllCars} />]}
      onClickReset={resetAction}
      leftMenuComponent={<TaxationFilter car={windowResults[currentCountry]?.car} handleTaxation={evaluateAllCars} />}
      mainComponent={
        <>
          <TaxationTopView
            isLoadingCarInformation={isLoadingCarInformation}
            car={windowResults[currentCountry]?.car}
            clickDealers={clickDealers}
            windowResults={windowResults}
            currentCountry={currentCountry}
            selectedCountry={selectedCountry}
            setClickDealers={setClickDealers}
            setSelectedCountry={setSelectedCountry}
            windowIsLoading={windowIsLoading}
          />
          <TaxationMainView
            carInformationIsFilled={carInformationIsFilled}
            evaluateCar={evaluateCar}
            carInformation={carInformation}
            clickDealers={clickDealers}
            evaluateAllCars={evaluateAllCars}
            selectedCountry={selectedCountry}
            windowIsLoading={windowIsLoading}
            windowResults={windowResults}
          />
        </>
      }
    />
  );
};
