export enum StringListAppSearchParam {
  OPTIONS = 'options',
  MAKES = 'makes',
  MODELS = 'models',
  BUILDS = 'builds',
  HPS = 'hps',
  LOCATION_NAMES = 'location_names',
  STATUSES = 'statuses',
  SUPPLY_TYPES = 'supply_types',
  BODIES = 'bodies',
  FUELS = 'fuels',
  GEARS = 'gears',
  GROUP_BY_COLUMNS = 'group_by_columns',
  AUCTION_WEBSITES = 'auction_websites',
  PORTAL_WEBSITES = 'portal_websites',
  HEALTHS = 'healths',
}
