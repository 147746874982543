export const isValidPhoneNumber = (phoneNumber) => {
    phoneNumber = (phoneNumber || "").replaceAll(" ", "")

    if(phoneNumber.startsWith("+31")) {
        return isValidateDutchPhoneNumber(phoneNumber)
    }
    return phoneNumber.length >= 11
}

const isValidateDutchPhoneNumber = (phoneNumber) => {
    return (phoneNumber.charAt(3) === '6' && phoneNumber.length === 12)
}