import 'i18next';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import HttpBackend from 'i18next-http-backend';
import { getProtocolAndDomain } from '../api/utils';

export enum TranslationFileType {
  FRONTEND = 'frontend',
  MARKET = 'market',
  MOBILE_APP = 'mobile-app',
  ADMIN = 'admin',
  PURCHASE = 'purchase',
}

declare module 'i18next' {
  interface CustomTypeOptions {
    returnNull: false;
  }
}

export const i18next = i18n
  .use(LanguageDetector)
  .use(HttpBackend)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    fallbackLng: false,
    returnNull: false,
    detection: {
      order: ['cookie'],
      caches: ['cookie'],
    },
    keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    backend: {
      loadPath: `${getProtocolAndDomain()}/translations?language={{lng}}&file_type=${TranslationFileType.FRONTEND}`,
      withCredentials: true,
    },
  });
