import React, { useMemo } from 'react';
import { SelectFilter, AppSearchParamKeys, useAppSearchParams } from '@japieglobal/shared/src/components';
import { Grid } from '@mui/material';
import { CarHeader, CarHeaderProps } from '@japieglobal/shared/src/api/services';

export const TaxationFilterGear = () => {
  const [{ make, model, fuel }] = useAppSearchParams([
    AppSearchParamKeys.MAKE,
    AppSearchParamKeys.MODEL,
    AppSearchParamKeys.FUEL,
  ]);
  const carHeaderOptions: CarHeaderProps = useMemo(
    () => ({ dealer_specific: false, type: CarHeader.GEAR, make, model, fuel }),
    [make, model, fuel],
  );
  const filterDependencies = useMemo(
    () => ({ [AppSearchParamKeys.MAKE]: make, [AppSearchParamKeys.MODEL]: model, [AppSearchParamKeys.FUEL]: fuel }),
    [make, model, fuel],
  );
  return (
    <Grid item xs={2}>
      <SelectFilter
        tabOnEnter
        hideResetButton
        searchParamKey={AppSearchParamKeys.GEAR}
        carHeaderOptions={carHeaderOptions}
        filterDependencies={filterDependencies}
        labelTranslationKey="GEAR"
      />
    </Grid>
  );
};
