import React from 'react';
import { FilterOptionToggles } from './extra-filters/filter-options-toggles';
import { FilterBuildYear } from './extra-filters/filter-build-year';
import { FilterMileage } from './extra-filters/filter-mileage';
import { FilterHorsepower } from './extra-filters/filter-horsepower';
import { FilterPrice } from './extra-filters/filter-price';
import { FilterOptions } from './extra-filters/filter-specials';
import { FilterWindowSize } from './extra-filters/filter-window-size';
import { FilterEtr } from './extra-filters/filter-etr';
import { FilterItr } from './extra-filters/filter-itr';
import { FilterLastPriceAdjustment } from './extra-filters/filter-last-price-adjustments';
import { FilterPriceDiscount } from './extra-filters/filter-price-discount';
import { FilterApr } from './extra-filters/filter-apr';
import { FilterMake } from './extra-filters/filter-make';
import { FilterModel } from './extra-filters/filter-model';
import { FilterFuel } from './extra-filters/filter-fuel';
import { FilterGear } from './extra-filters/filter-gear';
import { FilterBody } from './extra-filters/filter-body';
import { AppSearchParamKeys, useAppSearchParams } from '@japieglobal/shared';
import { FilterMessagePriceProposals } from './click-filters/filter-clicks';

export const FilterExtraFilters = () => {
  const [{ show_extra_filters }] = useAppSearchParams([AppSearchParamKeys.SHOW_EXTRA_FILTERS]);
  return show_extra_filters ? (
    <>
      <FilterMessagePriceProposals />
      <FilterMake />
      <FilterModel />
      <FilterOptions />
      <FilterOptionToggles />
      <FilterFuel />
      <FilterGear />
      <FilterBody />
      <FilterBuildYear />
      <FilterMileage />
      <FilterWindowSize />
      <FilterEtr />
      <FilterItr />
      <FilterApr />
      <FilterPrice />
      <FilterHorsepower />
      <FilterLastPriceAdjustment />
      <FilterPriceDiscount />
    </>
  ) : (
    <></>
  );
};
