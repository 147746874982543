import React from 'react';
import { SelectInput, SelectOption } from '@japieglobal/shared';
import {
  CsmReport,
  CsmReportKeys,
  DealerAccountStatus,
  updateDealerAccount,
  UpdateDealerAccountResponse,
} from '@japieglobal/shared/src/api/services';
import { useSnackbarErrorHandler } from '@japieglobal/shared/src/hooks';

interface StatusSelectCellProps {
  row: CsmReport;
  setNewDealerAccountRow: (newDealerAccount: UpdateDealerAccountResponse) => void;
  setRowsIsLoading: (isLoading: boolean) => void;
}
export const statusOptions = [
  { value: 'FOCUS', label: 'F' },
  { value: 'NORMAL', label: 'N' },
  { value: 'PILOT', label: 'P' },
];

export const StatusSelectCell = ({ setRowsIsLoading, setNewDealerAccountRow, row }: StatusSelectCellProps) => {
  const { snackbarErrorHandler } = useSnackbarErrorHandler();

  const handleSelect = (selectedOptions: SelectOption) => {
    setRowsIsLoading(true);
    updateDealerAccount({
      name: row[CsmReportKeys.DEALER],
      csm: row[CsmReportKeys.CSM],
      health: row[CsmReportKeys.HEALTH],
      [CsmReportKeys.STATUS]: selectedOptions.value as DealerAccountStatus,
    })
      .then((res) => setNewDealerAccountRow(res))
      .catch(snackbarErrorHandler)
      .finally(() => setRowsIsLoading(false));
  };

  const getValueOptions = (item?: DealerAccountStatus) => statusOptions.find((searchItem) => searchItem.value === item);

  return (
    <SelectInput value={getValueOptions(row[CsmReportKeys.STATUS])} setValue={handleSelect} options={statusOptions} />
  );
};
