import React from 'react';
import { CarResult } from '@japieglobal/shared/src/types/car-types';
import { ClicksPassedDown } from '../../../../states/click-states';
import { CarWindowOption } from './car-window-option';

export const CarWindowOptionsCell = React.memo(
  ({ car, clicks, order }: { car: CarResult; clicks?: ClicksPassedDown; order?: string[] }) => {
    return (
      <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', alignItems: 'center' }}>
        {clicks &&
          order?.map((carOption) => {
            return (
              <CarWindowOption
                key={carOption}
                option={carOption}
                options={clicks.options}
                carHasOption={car.options?.includes(carOption)}
              />
            );
          })}
      </div>
    );
  },
);
