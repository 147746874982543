import React from 'react';
import { useCarWindowStyles } from '../../car-window-styles';
import { ClickSpecialValueObj } from '../../../../states/click-states';
import { Tooltip } from '@japieglobal/shared/src/components';
import { useTranslation } from 'react-i18next';
import { OptionIcon, optionIcons, OptionLinkStyled } from '../../car-window-icons';
import { ClickState } from '@japieglobal/shared/src/types/click-state';
import { clickStateParser } from '../../../../utils/tri-state-parser';

interface OptionsClickLinkProps {
  option: string;
  options: ClickSpecialValueObj;
  carHasOption?: boolean;
}

export const formatAndShortenOption = (option) => {
  const subOption = option.substring(0, 3);
  return subOption[0].toUpperCase() + subOption.substring(1, 3).toLowerCase();
};

export const CarWindowOption = React.memo(({ option, options, carHasOption }: OptionsClickLinkProps) => {
  const styles = useCarWindowStyles();

  const { t } = useTranslation();

  const setValue = (value: ClickState) => {
    const newOptions = JSON.parse(options.value || '{}');
    newOptions[option] = value;
    options.setter(JSON.stringify(newOptions));
  };

  const clickState = JSON.parse(options.value || '{}')[option];
  const active = clickState == ClickState.LOCK;
  const antiLockIsActive = clickState == ClickState.ANTI_LOCK && !carHasOption;
  const enabled = carHasOption;

  const getClassName = () => {
    if (!optionIcons[option])
      return `${active && enabled ? styles.activeLink : ''} ${!enabled ? styles.absent : ''} ${enabled && !active ? styles.enabled : ''} ${antiLockIsActive ? styles.antiLock : ''}`;
    else return undefined;
  };

  return option ? (
    <Tooltip onHoverContent={t(option.toUpperCase(), option)}>
      <OptionLinkStyled
        href="#"
        onClick={(event: { preventDefault: () => void }) => {
          event.preventDefault();
          setValue(clickStateParser(clickState));
        }}
        className={getClassName()}
      >
        {optionIcons[option] ? (
          <OptionIcon
            option={option}
            active={active}
            missing={false}
            enabled={enabled}
            windowView={true}
            antiLock={antiLockIsActive}
          />
        ) : (
          formatAndShortenOption(t(option.toUpperCase()))
        )}
      </OptionLinkStyled>
    </Tooltip>
  ) : (
    <></>
  );
});
