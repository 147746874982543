import { AxiosResponse, AxiosError } from "axios";

export class NetworkError extends AxiosError {
  response: AxiosResponse;

  constructor(res: AxiosError) {
    super(`Invalid response: ${res.status || res.message}`);
    this.response = res.response!;
  }

  async getErrorMessage(): Promise<string> {
    const json = this.response.data;
    if (json && json.detail && typeof json.detail === "string") {
      return json.detail;
    }
    if (typeof this.response.data === "string") {
      return this.response.data;
    }
    return this.message;
  }
}
