import React, { useCallback, useMemo, useRef, useState } from 'react';
import { GridColDef, DataGrid } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import { styled, makeStyles } from '@mui/styles';
import { AdminMainWrapper } from '../admin-main-wrapper';
import { AdminQueryStateType } from '../hooks/admin-query-state';
import { useSnackbarErrorHandler } from '@japieglobal/shared/src/hooks';
import { renderCellExpand } from './data-grid-render-cell-expand';
import { useAdminDeleteUsers } from '../hooks/admin-delete-users';
import { useDebouncedEffect } from '@japieglobal/shared/src/hooks/use-debounced-effect';
import { AddIcon, DeleteIcon, TableActionsIcons } from '../components/icons';
import { adminList } from '@japieglobal/shared/src/api/services';
import { SuperAdminResponseType } from '@japieglobal/shared/src/api/services';

type SuperAdminResponseTypeForRows = SuperAdminResponseType & { id: string };

const StyledDataGrid = styled(DataGrid)({
  'overflow': undefined,
  'backgroundColor': 'white',
  '& .MuiDataGrid-columnHeader': {
    backgroundColor: 'white',
  },
});

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiDataGrid-colCellTitle': {
      fontSize: '12px',
      color: 'rgba(0,0,0,.54)',
    },
    '& .MuiDataGrid-cell': {
      fontSize: '13px',
      color: 'rgba(0,0,0,.87)',
    },
  },
}));

interface AdminMainAdminProps {
  adminQueryState: AdminQueryStateType;
}
export const AdminMainAdmin = React.memo(({ adminQueryState }: AdminMainAdminProps) => {
  const styles = useStyles();
  const { snackbarErrorHandler } = useSnackbarErrorHandler();
  const { t } = useTranslation();
  const [admins, setAdmins] = useState<SuperAdminResponseTypeForRows[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const gridRef = useRef<any>(null);

  const { selected, setSelectedProxy } = adminQueryState;

  const columns: GridColDef[] = useMemo(
    () => [
      { field: 'first_name', headerName: t('FIRST_NAME'), flex: 1, renderCell: renderCellExpand },
      { field: 'last_name', headerName: t('LAST_NAME'), flex: 1, renderCell: renderCellExpand },
      { field: 'email', headerName: 'E-Mail', flex: 1, renderCell: renderCellExpand },
      { field: 'dealer', headerName: 'Dealerholding', flex: 1, renderCell: renderCellExpand },
      {
        field: 'actie',
        headerName: t('ACTIVITY'),
        width: 120,
        renderCell: (params) => <TableActionsIcons userId={params.id as string} />,
      },
    ],
    [t],
  );

  const refreshUserList = useCallback(() => {
    setIsLoading(true);
    adminList()
      .then((res: SuperAdminResponseType[]) => {
        setAdmins(
          res.map((superAdmin) => {
            return {
              ...superAdmin,
              id: superAdmin.id,
            };
          }),
        );
      })
      .catch(snackbarErrorHandler)
      .finally(() => setIsLoading(false));
  }, [snackbarErrorHandler]);

  useDebouncedEffect(
    () => {
      refreshUserList();
    },
    300,
    [],
  );

  const { deleteUserDialogComponent, onClickDelete } = useAdminDeleteUsers({
    selected,
    setSelected: setSelectedProxy,
    refreshUserList,
  });

  const onSelectionChange = useCallback(
    (param) => {
      setSelectedProxy(param);
    },
    [setSelectedProxy],
  );

  const onPageChange = () => {
    if (gridRef?.current) {
      gridRef?.current?.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <AdminMainWrapper title={t('ADMINISTRATOR')}>
      <div style={{ display: 'flex', justifyContent: 'flex-end', paddingRight: '24px' }}>
        <DeleteIcon onClick={onClickDelete} disabled={selected.length === 0} />
        <AddIcon />
      </div>
      <StyledDataGrid
        ref={gridRef}
        autoHeight
        loading={isLoading}
        onRowSelectionModelChange={onSelectionChange}
        className={styles.root}
        rows={admins}
        disableRowSelectionOnClick
        columns={columns}
        checkboxSelection
        onPaginationModelChange={onPageChange}
      />
      {deleteUserDialogComponent}
    </AdminMainWrapper>
  );
});
