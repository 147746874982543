import { useSnackbarErrorHandler } from '@japieglobal/shared/src/hooks';
import { Alert, Box, CircularProgress, Grid, Link } from '@mui/material';
import { DataGrid, GridToolbar, GridRenderCellParams } from '@mui/x-data-grid';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { SuperAdminPageContainer } from '../super-admin-page-container';
import { getInvalidCars } from '@japieglobal/shared/src/api/services';
import { UserContext } from '@japieglobal/shared/src/user-context';

export const CarErrorsDownload = () => {
  const { snackbarErrorHandler } = useSnackbarErrorHandler();
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<any>();
  const { user } = useContext(UserContext);

  function renderUrl(params: GridRenderCellParams<any>) {
    return (
      <Link href={params.value} color="inherit" underline="hover" target="_blank">{params.value}</Link>
    );
  }

  const columns = useMemo(() => {
    if (!data?.length) return [];
    let cols = ['error_code', 'error_message'];
    cols = cols.concat(Object.keys(data[0]).filter((c) => !cols.includes(c)));
    return cols.map((k) => ({ field: k, renderCell: k.includes("url") ? renderUrl : undefined }));
  }, [data]);

  useEffect(() => {
    setLoading(true);
    getInvalidCars(user.dealer)
      .then(setData)
      .catch(snackbarErrorHandler)
      .finally(() => setLoading(false));
  }, [user.dealer, snackbarErrorHandler]);

  return (
    <SuperAdminPageContainer>
      <strong>Car Errors for {user.dealer}</strong>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <div style={{ height: 600, width: '100%' }}>
            {loading && (
              <Box m={5}>
                <CircularProgress />
              </Box>
            )}
            {!loading && !!data?.length && (
              <DataGrid
                rows={data}
                columns={columns}
                pageSizeOptions={[10, 50, 100]}
                density="compact"
                disableRowSelectionOnClick
                pagination
                getRowId={(row) => row.id || row.url_redirect}
                slots={{ toolbar: GridToolbar }}
              />
            )}
            {!data?.length && !loading && <Alert severity="error">Data is not available</Alert>}
          </div>
        </Grid>
      </Grid>
    </SuperAdminPageContainer>
  );
};
