import { AppSearchParamKey, AppSearchParamKeys } from '@japieglobal/shared';
import { CsmReport, CsmReportKeys } from '@japieglobal/shared/src/api/services';

export type CellRender =
  | 'basic'
  | 'link'
  | 'health'
  | 'date'
  | 'comments'
  | 'csmSelect'
  | 'healthSelect'
  | 'statusSelect';
export type HoverRender = 'score' | 'table';

export interface CsmColumn {
  field: keyof CsmReport;
  headerName: string;
  headerDescription: string;
  flex: number;
  type: string;
  cellRender: CellRender;
  hoverRender?: HoverRender;
  searchParamKey: AppSearchParamKey;
}

export const csmColumns: CsmColumn[] = [
  {
    field: CsmReportKeys.STATUS,
    headerName: 'Status',
    headerDescription: 'Status',
    flex: 0.8,
    type: 'string',
    cellRender: 'statusSelect',
    searchParamKey: AppSearchParamKeys.STATUSES,
  },
  {
    field: CsmReportKeys.DEALER,
    headerName: 'Dealer',
    headerDescription: 'Dealer',
    flex: 2,
    type: 'string',
    cellRender: 'link',
    searchParamKey: AppSearchParamKeys.DEALER,
  },
  {
    field: CsmReportKeys.TOTAL_USAGE_CHANGE,
    headerName: 'Total usage change (%)',
    headerDescription: 'Total usage change (%)',
    flex: 0.5,
    type: 'string',
    cellRender: 'basic',
    hoverRender: 'table',
    searchParamKey: AppSearchParamKeys.TOTAL_USAGE_CHANGE,
  },
  {
    field: CsmReportKeys.TOTAL_CALLS_CHANGE,
    headerName: 'Total calls change (%)',
    headerDescription: 'Total calls change (%)',
    flex: 0.5,
    type: 'string',
    cellRender: 'basic',
    hoverRender: 'table',
    searchParamKey: AppSearchParamKeys.TOTAL_CALLS_CHANGE,
  },
  {
    field: CsmReportKeys.TOTAL_USAGE_0_30,
    headerName: 'Total usage 0 - 30 days (%)',
    headerDescription: 'Total usage 0 - 30 days (%)',
    flex: 0.5,
    type: 'string',
    cellRender: 'basic',
    searchParamKey: AppSearchParamKeys.TOTAL_USAGE_0_30,
  },
  {
    field: CsmReportKeys.ARR_CATEGORY,
    headerName: 'Arr Category',
    headerDescription: 'Arr Category',
    flex: 0.2,
    type: 'number',
    cellRender: 'basic',
    searchParamKey: AppSearchParamKeys.ARR_CATEGORY,
  },
  {
    field: CsmReportKeys.AUTO_ACCOUNT_HEALTH,
    headerName: 'Auto Account Health',
    headerDescription: 'Auto Account Health',
    flex: 0.2,
    type: 'string',
    cellRender: 'health',
    hoverRender: 'score',
    searchParamKey: AppSearchParamKeys.AUTO_ACCOUNT_HEALTH,
  },
  {
    field: CsmReportKeys.HEALTH,
    headerName: 'Health',
    headerDescription: 'Health',
    flex: 0.5,
    type: 'string',
    cellRender: 'healthSelect',
    searchParamKey: AppSearchParamKeys.HEALTHS,
  },
  {
    field: CsmReportKeys.END_DATE_CONTRACT,
    headerName: 'Einde contract',
    headerDescription: 'Einde contract',
    flex: 1,
    type: 'string',
    cellRender: 'date',
    searchParamKey: AppSearchParamKeys.END_DATE_CONTRACT,
  },
  {
    field: CsmReportKeys.CSM,
    headerName: 'CSM',
    headerDescription: 'CSM',
    flex: 1,
    type: 'string',
    cellRender: 'csmSelect',
    searchParamKey: AppSearchParamKeys.CSM,
  },
  {
    field: CsmReportKeys.LAST_COMMENT_DATE,
    headerName: 'Last comment',
    headerDescription: 'Last comment',
    flex: 1,
    type: 'string',
    cellRender: 'date',
    searchParamKey: AppSearchParamKeys.LAST_COMMENT_DATE,
  },
  {
    field: CsmReportKeys.COMMENTS,
    headerName: 'MESSAGES',
    headerDescription: 'MESSAGES',
    flex: 0.5,
    type: 'string',
    cellRender: 'comments',
    searchParamKey: AppSearchParamKeys.COMMENTS,
  },
];
