import { Grid } from '@mui/material';
import React from 'react';
import { CarResult } from '@japieglobal/shared/src/types/car-types';
import { DisplayHints } from '@japieglobal/shared/src/components/hints/display-hints';
import { TaxationTopFilters } from './top-side-filters/taxation-top-filter';
import { ShoppingCartButton } from '../../taxation-and-results-shared/buttons/shopping-cart-button';
import { MailButton } from '../../taxation-and-results-shared/buttons/mail-button/mail-button';
import { ResultTableTrace } from '../../taxation-and-results-shared/buttons/result-table-trace-button';
import { SaveTaxation } from '../../saved-taxations/save-taxation';

interface TaxationCarViewProps {
  car: CarResult | undefined;
}
export const TaxationCarView = ({ car }: TaxationCarViewProps) => (
  <Grid container spacing={2}>
    <TaxationTopFilters />
    {car && (
      <Grid container alignItems={'center'} item xs={4} columnSpacing={2}>
        <Grid item>
          <ShoppingCartButton car={car} />
        </Grid>
        <Grid item>
          <SaveTaxation car={car} />
        </Grid>
        <Grid item>
          <MailButton isTaxation car={car} />
        </Grid>
        <Grid item>
          <ResultTableTrace car={car} />
        </Grid>
      </Grid>
    )}
    <DisplayHints />
  </Grid>
);
