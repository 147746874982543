import { TranslationFileType } from '../../i18n/i18n';
import { instance } from './instance';

export const valuateFile = async (
  body: FormData,
  props: {
    template: string;
    use_standard_options: boolean;
    location_name: string | null;
    enable_other_countries: boolean;
    send_email: boolean;
  },
) =>
  instance.post(`/api/valuate/file`, body, {
    responseType: 'blob',
    params: props,
    headers: { 'Content-Type': 'multipart/form-data' },
  });

export const uploadFile = async (body: FormData, dealer: string, props: { filename: string; append: boolean }) =>
  instance
    .post(`/api/dealers/${dealer}/storage`, body, { params: props, headers: { 'Content-Type': 'multipart/form-data' } })
    .then((res) => res.data);

export const existsFile = async (dealer: string, props: { filename: string }) =>
  instance.get(`/api/dealers/${dealer}/storage/exists`, { params: props }).then((res) => res.data);

export const deleteFile = async (dealer: string, props: { filename: string }) =>
  instance.delete(`/api/dealers/${dealer}/storage`, { params: props }).then((res) => res.data);

export const downloadFile = async (dealer: string, props: { filename: string }) =>
  instance.get(`/api/dealers/${dealer}/storage`, {
    responseType: 'blob',
    params: props,
    headers: { 'Content-Type': 'multipart/form-data' },
  });

export const downloadTranslationFile = async (file_type: TranslationFileType) =>
  instance.get(`/translations/file`, {
    params: { file_type },
    responseType: 'blob',
    headers: { 'Content-Type': 'multipart/form-data' },
  });

export const uploadTranslationFile = async (file_type: TranslationFileType, body: FormData) =>
  instance
    .post(`/translations`, body, { params: { file_type }, headers: { 'Content-Type': 'multipart/form-data' } })
    .then((res) => res);
