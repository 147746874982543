import React from 'react';
import { CellRender, CsmColumn } from '../csm-report-columns';
import moment from 'moment';
import { LinkCell } from './cell-content/link-cell';
import { HealthCell } from './cell-content/health-cell';
import {
  AppSearchParamKeys,
  RenderCellDefParams,
  Tooltip,
  useAppSearchParams,
} from '@japieglobal/shared/src/components';
import { ScoreTable } from './cell-hover/score-table';
import { HoverTable } from './cell-hover/hover-table';
import { ActionsCell } from './cell-content/messages-cell/messages-cell';
import {
  CsmReport,
  CsmReportKeys,
  DataType,
  DealerAccountHealth,
  SuperAdminResponseType,
  UpdateDealerAccountResponse,
} from '@japieglobal/shared/src/api/services';
import { CsmSelectCell } from './cell-content/csm-select-cell';
import { HealthSelectCell } from './cell-content/health-select-cell';
import { Grid } from '@mui/material';
import { StatusSelectCell } from './cell-content/status-select-cell';

interface CellContentProps {
  cellRender: CellRender;
  renderCellParams: RenderCellDefParams<CsmReport>;
  superAdmins: SuperAdminResponseType[];
  setNewDealerAccountRow: (newDealerAccount: UpdateDealerAccountResponse) => void;
  setRowsIsLoading: (isLoading: boolean) => void;
}
interface CellRenderWrapperProps {
  renderCellParams: RenderCellDefParams<CsmReport>;
  csmColumn: CsmColumn;
  superAdmins: SuperAdminResponseType[];
  setNewDealerAccountRow: (newDealerAccount: UpdateDealerAccountResponse) => void;
  setRowsIsLoading: (isLoading: boolean) => void;
}

const CellContent = ({
  setRowsIsLoading,
  setNewDealerAccountRow,
  cellRender,
  renderCellParams,
  superAdmins,
}: CellContentProps) => {
  const [{ data_type }] = useAppSearchParams([AppSearchParamKeys.DATA_TYPE]);

  const getActivityPageRoute = () => {
    if (data_type === DataType.MONITOR) return 'users/';
    else if (data_type === DataType.API) return 'service_accounts/';
    else if (data_type === DataType.PURCHASE) return 'activity_purchase_view/';
  };

  return (
    <>
      {cellRender === 'basic' && <>{renderCellParams.value}</>}
      {cellRender === 'date' && <>{renderCellParams.value && moment(renderCellParams.value).format('DD-MM-YYYY')}</>}
      {cellRender === 'health' && (
        <Grid container>
          <HealthCell value={renderCellParams.value as DealerAccountHealth} />
        </Grid>
      )}
      {cellRender === 'comments' && (
        <ActionsCell messages={renderCellParams.value} dealerAccountName={renderCellParams.row[CsmReportKeys.DEALER]} />
      )}
      {cellRender === 'link' &&
        (renderCellParams.value as string).split(',').map((name, index) => (
          <span key={name}>
            {index ? ', ' : ''}
            <LinkCell
              link={`/admin/${getActivityPageRoute()}?switch_to_dealer=${name}&period=last_60_days&userFilter[dealer]=${name}`}
              value={name}
            />
          </span>
        ))}
      {cellRender === 'csmSelect' && (
        <CsmSelectCell
          setRowsIsLoading={setRowsIsLoading}
          setNewDealerAccountRow={setNewDealerAccountRow}
          superAdmins={superAdmins}
          row={renderCellParams.row}
        />
      )}
      {cellRender === 'healthSelect' && (
        <HealthSelectCell
          setRowsIsLoading={setRowsIsLoading}
          setNewDealerAccountRow={setNewDealerAccountRow}
          row={renderCellParams.row}
        />
      )}
      {cellRender === 'statusSelect' && (
        <StatusSelectCell
          setRowsIsLoading={setRowsIsLoading}
          setNewDealerAccountRow={setNewDealerAccountRow}
          row={renderCellParams.row}
        />
      )}
    </>
  );
};

export const CellRenderWrapper = ({
  renderCellParams,
  csmColumn: { cellRender, hoverRender, field },
  superAdmins,
  setNewDealerAccountRow,
  setRowsIsLoading,
}: CellRenderWrapperProps) => (
  // Even tho CellContent is here used 3 times the exact same way,
  // making a separate component out of it to re-use will cause the clicks to stop working in the table.
  // Thats why we accepted a little duplicated code here.
  <>
    {hoverRender === 'score' && (
      <Tooltip
        onHoverContent={<ScoreTable hoverValues={renderCellParams.row[field] as { [key: string]: number | string }} />}
      >
        <CellContent
          setRowsIsLoading={setRowsIsLoading}
          setNewDealerAccountRow={setNewDealerAccountRow}
          superAdmins={superAdmins}
          cellRender={cellRender}
          renderCellParams={renderCellParams}
        />
      </Tooltip>
    )}
    {hoverRender === 'table' && (
      <Tooltip
        onHoverContent={<HoverTable hoverValues={renderCellParams.row[field] as { [key: string]: number | string }} />}
      >
        <CellContent
          setRowsIsLoading={setRowsIsLoading}
          setNewDealerAccountRow={setNewDealerAccountRow}
          superAdmins={superAdmins}
          cellRender={cellRender}
          renderCellParams={renderCellParams}
        />
      </Tooltip>
    )}
    {!hoverRender && (
      <CellContent
        setRowsIsLoading={setRowsIsLoading}
        setNewDealerAccountRow={setNewDealerAccountRow}
        superAdmins={superAdmins}
        cellRender={cellRender}
        renderCellParams={renderCellParams}
      />
    )}
  </>
);
