import React, { useMemo } from 'react';
import { SelectInput, SelectOption, valuesToSelectOptions, valueToSelectOption } from '@japieglobal/shared';
import {
  CsmReport,
  CsmReportKeys,
  SuperAdminResponseType,
  updateDealerAccount,
  UpdateDealerAccountResponse,
} from '@japieglobal/shared/src/api/services';
import { useSnackbarErrorHandler } from '@japieglobal/shared/src/hooks';

interface CsmSelectCellProps {
  row: CsmReport;
  superAdmins: SuperAdminResponseType[];
  setNewDealerAccountRow: (newDealerAccount: UpdateDealerAccountResponse) => void;
  setRowsIsLoading: (isLoading: boolean) => void;
}

export const CsmSelectCell = ({ setRowsIsLoading, setNewDealerAccountRow, row, superAdmins }: CsmSelectCellProps) => {
  const { snackbarErrorHandler } = useSnackbarErrorHandler();

  const options = useMemo(
    () => valuesToSelectOptions(superAdmins.map((superAdmin) => superAdmin.full_name || superAdmin.email)),
    [superAdmins],
  );

  const handleSelect = (selectedOptions: SelectOption) => {
    setRowsIsLoading(true);
    updateDealerAccount({
      name: row[CsmReportKeys.DEALER],
      health: row[CsmReportKeys.HEALTH],
      status: row[CsmReportKeys.STATUS],
      [CsmReportKeys.CSM]: selectedOptions.value,
    })
      .then((res) => setNewDealerAccountRow(res))
      .catch(snackbarErrorHandler)
      .finally(() => setRowsIsLoading(false));
  };

  return <SelectInput value={valueToSelectOption(row[CsmReportKeys.CSM])} setValue={handleSelect} options={options} />;
};
