import { Dialog, DialogContent, DialogTitle, useMediaQuery, useTheme } from '@mui/material';
import { styled } from '@mui/styles';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { CarCompetitorView } from './car-competitor-view';
import { CarResult } from '@japieglobal/shared/src/types/car-types';
import { useQueryString, useSnackbarErrorHandler } from '@japieglobal/shared/src/hooks';
import { urlParseNumber } from '../../utils/url-value-parsers';
import { reorderList } from '../../utils/reorder-list';
import { queryStateMultipleAtOnce } from '../../../../shared/src/utils/query-state-set-multiple-at-once';
import { addCarBasedOnRank } from '../../utils/add-car-to-list-rank-based';
import { useClickQueryStates } from '../../states/click-states';
import { useDebouncedEffect } from '@japieglobal/shared/src/hooks/use-debounced-effect';
import { ProgressIndicator } from '@japieglobal/shared';
import { PricingType } from '@japieglobal/shared/src/types/pricing-type';
import { CarQuery, carWindow } from '@japieglobal/shared/src/api/services';
import { TopDealer } from '@japieglobal/shared/src/types';
import { Pageable } from '@japieglobal/shared/src/types/pagination';
import { usePriceRankingStates } from '../../states/price-ranking';

const InitialContent = styled(`div`)({ height: '200px' });

const StyledDialogContent = styled(DialogContent)({ flex: '0 0 auto' });

interface CompetitorDialogProps {
  showDialog: boolean;
  setShowDialog: (val: boolean) => void;
  carId: number | undefined;
  reloadCarList: (id: number) => void;
}

export const CompetitorDialog = React.memo(
  ({ carId, showDialog, setShowDialog, reloadCarList }: CompetitorDialogProps) => {
    const { pricingTarget, priceDiscount, setPricingTarget, setPriceDiscount, resetPriceRanking } =
      usePriceRankingStates();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const navigate = useNavigate();
    const { t } = useTranslation();

    const { snackbarErrorHandler } = useSnackbarErrorHandler();

    const [reloadCarListCounter, setReloadCarListCounter] = useState(0);

    const [car, setCar] = useState<CarResult | undefined>(undefined);
    const [competitorCars, setCompetitorCars] = useState<CarResult[]>([]);
    const [alternativePageIndex, setAlternativePageIndex] = useQueryString(
      'alternative_page_index',
      0,
      false,
      urlParseNumber,
    );
    const [alternativePageSize, setAlternativePageSize] = useQueryString(
      'alternative_page_size',
      10,
      false,
      urlParseNumber,
    );
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [pageable, setPageable] = useState<Pageable | undefined>(undefined);

    const [topDealers, setTopDealers] = useState<TopDealer[] | undefined>(undefined);

    const reloadCarListInDialog = useCallback(
      (id: number) => {
        setReloadCarListCounter((prevState) => prevState + 1);
        resetPriceRanking();
        reloadCarList(id);
      },
      [reloadCarList, resetPriceRanking],
    );

    const { clickQuerySetters, getClicksFromCar, clicksAPIStyle } = useClickQueryStates();

    const changePage = useCallback(
      (pageIndex: number) => {
        resetPriceRanking();
        setAlternativePageIndex(pageIndex);
      },
      [resetPriceRanking, setAlternativePageIndex],
    );

    const handleClose = useCallback(async () => {
      setShowDialog(false);
      resetPriceRanking();

      const setter = [
        { func: clickQuerySetters.setClickOwnSupply, value: undefined },
        { func: clickQuerySetters.setClickEquipmentInStock, value: undefined },
        { func: clickQuerySetters.setClickEquipmentIsDealer, value: undefined },
        { func: clickQuerySetters.setClickStatLocalCar, value: undefined },
        { func: clickQuerySetters.setClickHorsePower, value: undefined },
        { func: clickQuerySetters.setClickModelYear, value: undefined },
        { func: clickQuerySetters.setClickBuild, value: undefined },
        { func: clickQuerySetters.setClickOptions, value: undefined },
        { func: clickQuerySetters.setClickIncludeBanned, value: undefined },
        { func: clickQuerySetters.setClickDealer, value: undefined },
        { func: setAlternativePageSize, value: undefined },
        { func: setAlternativePageIndex, value: undefined },
      ];

      const url = await queryStateMultipleAtOnce({ valueSetters: setter, handleError: snackbarErrorHandler });
      if (url) {
        navigate(url);
      }
    }, [
      clickQuerySetters.setClickBuild,
      clickQuerySetters.setClickEquipmentInStock,
      clickQuerySetters.setClickEquipmentIsDealer,
      clickQuerySetters.setClickHorsePower,
      clickQuerySetters.setClickModelYear,
      clickQuerySetters.setClickOwnSupply,
      clickQuerySetters.setClickStatLocalCar,
      clickQuerySetters.setClickOptions,
      clickQuerySetters.setClickIncludeBanned,
      clickQuerySetters.setClickDealer,
      navigate,
      setAlternativePageIndex,
      setAlternativePageSize,
      resetPriceRanking,
      setShowDialog,
      snackbarErrorHandler,
    ]);

    const clickFiltersJustSet = useRef(false);

    const clicksPassedDown = useMemo(() => getClicksFromCar(car), [car, getClicksFromCar]);

    useEffect(() => {
      if (clicksAPIStyle) {
        setAlternativePageIndex(0);
      }
    }, [clicksAPIStyle, setAlternativePageIndex]);

    useDebouncedEffect(
      () => {
        if (carId) {
          const queryProps: CarQuery = {
            window_car_id: carId,
            page_size: alternativePageSize,
            page_index: (pricingTarget !== undefined ? pageable?.page_index : null) || alternativePageIndex,
            ...clicksAPIStyle,
          };
          if (priceDiscount !== undefined) {
            queryProps.pricing_target = priceDiscount;
            queryProps.pricing_type = PricingType.DISCOUNT;
          } else if (pricingTarget !== undefined) {
            queryProps.pricing_target = pricingTarget;
            queryProps.pricing_type = PricingType.POSITION;
          }

          if (clickFiltersJustSet.current === true) {
            clickFiltersJustSet.current = false;
          } else {
            setIsLoading(true);
            carWindow(queryProps)
              .then((res) => {
                if (res.car) {
                  setCar(res.car);
                  setCompetitorCars(addCarBasedOnRank(res.car, res.window, alternativePageSize));
                  setTopDealers(res.top_dealers);
                }
                setPageable(res.pagable);
              })
              .catch(snackbarErrorHandler)
              .finally(() => setIsLoading(false));
          }
        }
      },
      500,
      [
        clicksAPIStyle,
        reloadCarListCounter,
        priceDiscount,
        pricingTarget,
        carId,
        alternativePageIndex,
        alternativePageSize,
      ],
    );

    const reorder = useCallback(
      (source: number, target: number) => {
        const newTarget = competitorCars[target]?.rank?.counter;

        setCompetitorCars((prevState) => {
          return reorderList(prevState, source, target);
        });
        if (newTarget !== undefined) {
          setPriceDiscount(undefined);
          setPricingTarget(newTarget);
        }
      },
      [competitorCars, setPriceDiscount, setPricingTarget],
    );

    const pageCount = pageable?.page_count ?? 0;
    const showEmptyResult = car && pageable && pageable.total <= 0;

    const carCompetitorView = CarCompetitorView({
      removeRanking: resetPriceRanking,
      car,
      cars: competitorCars,
      reorder,
      currentPage: pageable?.page_index || alternativePageIndex,
      changePage,
      pageCount,
      reloadCarList: reloadCarListInDialog,
      clicks: clicksPassedDown,
      closeDialog: handleClose,
      topDealers,
      error: showEmptyResult ? 'NO_RESULTS_ADJUST_PARAMETERS' : undefined,
    });
    const normalContent = car && (
      <>
        <DialogTitle>{t('WINDOW')}</DialogTitle>
        <StyledDialogContent dividers>{carCompetitorView.main}</StyledDialogContent>
        <DialogContent dividers>{carCompetitorView.rows}</DialogContent>
        {car && pageCount > 0 && <StyledDialogContent>{carCompetitorView.pagination}</StyledDialogContent>}
      </>
    );

    return (
      <Dialog
        fullScreen={fullScreen}
        maxWidth="lg"
        fullWidth
        onClose={handleClose}
        aria-labelledby="export"
        open={showDialog}
        disableEnforceFocus
      >
        {car === undefined ? <InitialContent /> : normalContent}
        {isLoading && <ProgressIndicator />}
      </Dialog>
    );
  },
);
