import React, { useCallback, useEffect, useState } from 'react';
import {
  AppSearchParamKeys,
  BaseTable,
  ConfirmationDialog,
  TableColDef,
  useAppSearchParams,
} from '@japieglobal/shared';
import { clusterManagementColumns } from './columns';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ContentCutIcon from '@mui/icons-material/ContentCut';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { useSnackbarErrorHandler } from '@japieglobal/shared/src/hooks';
import { IconButton, Link } from '@mui/material';
import { PageType, SuperAdminPageContainer } from '../../super-admin-page-container';
import { deleteOption, getOptions } from '@japieglobal/shared/src/api/services';
import { OptionListItem } from '@japieglobal/shared/src/api/services';

interface ActionsProps {
  handleDelete: () => void;
  id: number;
}

export const AddIcon = () => (
  <IconButton component={Link} href={`/super-admin/${PageType.OPTIONS_MANAGEMENT}/create`} aria-label="add">
    <AddCircleIcon color="primary" fontSize="large" />
  </IconButton>
);

const Actions = ({ handleDelete, id }: ActionsProps) => (
  <>
    <IconButton href={`/super-admin/${PageType.OPTIONS_MANAGEMENT}/edit/${id}`} LinkComponent={Link} color="primary">
      <ContentCutIcon />
    </IconButton>
    <IconButton color="warning" onClick={handleDelete}>
      <DeleteForeverIcon />
    </IconButton>
  </>
);

export const OptionsManagement = () => {
  const [options, setOptions] = useState<OptionListItem[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [optionToDelete, setOptionToDelete] = useState<OptionListItem>();
  const { snackbarErrorHandler, snackbarSuccessMessage } = useSnackbarErrorHandler();

  const [{ make, model }] = useAppSearchParams([AppSearchParamKeys.MAKE, AppSearchParamKeys.MODEL]);

  const handleGetOptions = useCallback(() => {
    setIsLoading(true);
    getOptions({ model, make })
      .then(setOptions)
      .catch(snackbarErrorHandler)
      .finally(() => setIsLoading(false));
  }, [make, model, snackbarErrorHandler]);

  const handleDelete = (id: number, name: string) => {
    setIsLoading(true);
    deleteOption(id)
      .then(() => {
        handleGetOptions();
        snackbarSuccessMessage(`The option ${name} has been permanently delete 🥺`);
      })
      .catch(snackbarErrorHandler)
      .finally(() => {
        setIsLoading(false);
        setOptionToDelete(undefined);
      });
  };

  const closeDialog = () => {
    setOptionToDelete(undefined);
    setOpenDialog(false);
  };

  useEffect(() => {
    handleGetOptions();
  }, [handleGetOptions]);

  const columns: TableColDef<OptionListItem>[] = clusterManagementColumns.map((column) => ({
    ...column,
    headerAlign: 'left',
    align: 'left',
    type: 'string',
    renderCell: (params) =>
      column.cellRenderLogic === 'actions' ? (
        <Actions
          handleDelete={() => {
            setOpenDialog(true);
            setOptionToDelete(params.row);
          }}
          id={params.value}
        />
      ) : undefined,
  }));

  return (
    <SuperAdminPageContainer>
      <div style={{ display: 'flex', justifyContent: 'flex-end', paddingRight: '24px' }}>
        <AddIcon />
      </div>
      <BaseTable
        isLoading={isLoading}
        rows={options}
        columns={columns}
        getRowId={(row) => row.id}
        headerHeight={55}
        rowHeight={35}
      />
      {openDialog && (
        <ConfirmationDialog
          yesAction={closeDialog}
          title="Gooi gewoon weg? 🤔"
          question={`Are you sure you want to delete ${optionToDelete?.name}? You can not wind back this action.`}
          closeDialog={closeDialog}
          noAction={() => {
            if (optionToDelete !== undefined) {
              handleDelete(optionToDelete.id, optionToDelete?.name);
            }
          }}
          textYes="No"
          textNo="Yes"
        />
      )}
    </SuperAdminPageContainer>
  );
};
