import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSnackbarErrorHandler } from '@japieglobal/shared/src/hooks';
import {
    ColoredButton,
    LoadingWrapper,
    TextInput,
    valuesToSelectOptions,
} from '@japieglobal/shared';
import { PageType, SuperAdminPageContainer } from '../../super-admin-page-container';
import { OptionInputs } from './option-inputs';
import { Box } from '@mui/material';
import { createOption, Option } from '@japieglobal/shared/src/api/services';
import {ModelMultiSelect} from "./selects/model-multi-select";
import {MakeMultiSelect} from "./selects/make-multi-select";

export const OptionsManagementCreate = () => {
  const [option, setOption] = useState<Option>({
    id: 0,
    name: '',
    makes: null,
    models: null,
    is_body: false,
    is_pseudo: false,
    pattern: [],
    default_options: [],
    ban: [],
    super_pattern: [],
  });
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { snackbarErrorHandler } = useSnackbarErrorHandler();

  const handleCreate = () => {
    setIsLoading(true);
    createOption(option)
      .then(() => navigate(`/super-admin/${PageType.OPTIONS_MANAGEMENT}`))
      .catch(snackbarErrorHandler)
      .finally(() => setIsLoading(false));
  };

  return (
    <LoadingWrapper isLoading={isLoading}>
      <SuperAdminPageContainer>
        <b>Create option</b>
        <Box display={'flex'} flexDirection={'column'} rowGap={'10px'}>
          <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'} columnGap={'10px'}>
            <TextInput label="NAME" setValue={(value) => setOption({ ...option, name: value })} value={option.name} />
            <MakeMultiSelect
              setValue={(value) => setOption({ ...option, makes: value.map(v => v.value) })}
              value={valuesToSelectOptions(option.makes ?? [])}
            />
            <ModelMultiSelect
              standardOption={{
                make: option.makes?.[0] || '',
                body: null,
                build: null,
                fuel: null,
                hp: null,
                id: 0,
                model: null,
                option_id: 0,
              }}
              setValue={(value) => setOption({ ...option, models:  value.map(v => v.value) })}
              value={valuesToSelectOptions(option.models ?? [])}
            />
          </Box>
          <OptionInputs option={option} setOption={setOption} />
        </Box>
        <ColoredButton disabled={isLoading} onClick={handleCreate}>
          save
        </ColoredButton>
      </SuperAdminPageContainer>
    </LoadingWrapper>
  );
};
