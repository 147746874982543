import React, { useCallback, useEffect, useState } from 'react';
import {
  Autocomplete,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import StorageIcon from '@mui/icons-material/Storage';
import { LoadingWrapper } from '../../../loading-wrapper/loading-wrapper';
import { useDebouncedEffect } from '../../../../hooks/use-debounced-effect';
import { useQueryString } from '../../../../hooks';
import { MenuButton } from '../../menu-button';
import { dealerSearch, setDealer, User, UserRoles } from '../../../../api/services';

interface DealerButtonProps {
  user: User;
}
export const DealerButton = ({ user }: DealerButtonProps) => {
  const theme = useTheme();
  const [showPopup, setShowPopup] = useState(false);
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [dealers, setDealers] = useState<string[]>([]);
  const [value, setValue] = React.useState<string | undefined>(user.dealer);
  const [inputValue, setInputValue] = useState<string>(user.dealer);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleOpen = () => setShowPopup(true);
  const handleClose = () => setShowPopup(false);

  const [switchToDealer, setSwitchToDealer] = useQueryString<string | undefined>('switch_to_dealer', undefined);

  useEffect(() => {
    if (showPopup) {
      setValue(user.dealer);
    }
  }, [showPopup, user.dealer]);

  useEffect(() => {
    if (user.role !== UserRoles.SUPER_ADMIN) {
      setDealers(user.allowed_dealers);
    }
  }, [user, user.allowed_dealers, user.role]);

  useDebouncedEffect(
    () => {
      if (user.role === UserRoles.SUPER_ADMIN) {
        if (inputValue.length > 0) {
          setIsLoading(true);
          dealerSearch({ name: inputValue })
            .then((res) => {
              setDealers(res.results.map((value1) => value1.name));
            })
            // .catch(snackbarErrorHandler)
            .finally(() => setIsLoading(false));
        } else {
          setDealers([]);
        }
      }
    },
    500,
    [inputValue],
  );

  const handleSetDealer = useCallback(
    (dealer: string) => {
      if (user.dealer !== dealer) {
        setIsLoading(true);
        setDealer(dealer)
          .then(() => {
            setSwitchToDealer(undefined);
            window.location.reload();
          })
          .catch((error) => {
            console.error(error);
          })
          .finally(() => setIsLoading(false));
      }
    },
    [setSwitchToDealer, user.dealer],
  );

  useEffect(() => {
    if (value) {
      handleSetDealer(value);
    }
  }, [handleSetDealer, value]);

  useEffect(() => {
    if (switchToDealer) {
      setSwitchToDealer(undefined);
      handleSetDealer(switchToDealer);
    }
  }, [handleSetDealer, setSwitchToDealer, switchToDealer]);

  return user.role === UserRoles.SUPER_ADMIN || user.allowed_dealers?.length > 1 ? (
    <>
      <MenuButton hoverTitle={'DEALER'} onClick={handleOpen}>
        <StorageIcon />
      </MenuButton>
      <Dialog
        fullScreen={fullScreen}
        onClose={handleClose}
        aria-labelledby="select dealer"
        open={showPopup}
        disableEnforceFocus
      >
        <DialogTitle id="customized-dialog-title">Select dealer</DialogTitle>
        <LoadingWrapper isLoading={isLoading}>
          <DialogContent dividers>
            <Autocomplete
              options={dealers}
              style={{ width: 300 }}
              value={value}
              disableClearable
              autoHighlight
              onChange={(event: any, newValue: string | null) => {
                setValue(newValue ?? undefined);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  value={inputValue}
                  onChange={(event) => {
                    setInputValue(event.target.value);
                  }}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                  variant="outlined"
                />
              )}
            />
          </DialogContent>
        </LoadingWrapper>
      </Dialog>
    </>
  ) : (
    <></>
  );
};
