import { ToggleFilter, Tooltip } from '@japieglobal/shared/src';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import RemoveIcon from '@mui/icons-material/Remove';
import { Box, Grid, Table, TableBody } from '@mui/material';
import { styled } from '@mui/system';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { compareMemoProps } from '../../utils/compare-react-props';
import { CarTurnoverChartTiny } from '../../cars/turnover-chart/car-turnover-chart-tiny';
import { useTranslation } from 'react-i18next';
import { BooleanAppSearchParam } from '@japieglobal/shared/src/components';
import { Colors } from '@japieglobal/shared/src/style/palettes/theme-colors';
import { CarResult, CarTurnover } from '@japieglobal/shared/src/types';
import { TableCell } from '../../cars/results/car-popup-styles';
import { UserContext } from '@japieglobal/shared/src/user-context';

const TurnOverWrapper = styled(`div`)({ marginbottom: '3px', display: 'flex', flexdirection: 'row' });

const StyledToggleFilter = styled(ToggleFilter)<{
  trend: number | undefined;
}>(({ trend }) => ({
  'width': '25px',
  'maxWidth': '25px',
  'maxHeight': '15px',
  'minWidth': '25px',
  'minHeight': '15px',
  'position': 'relative',
  'height': '15px',
  'paddingLeft': '2px',
  'borderRadius': '0',
  'fontSize': '10px',
  'backgroundColor': Colors.WHITE,
  'color': '#333',
  'marginRight': '3px',
  'cursor': 'pointer',
  '&:hover': {
    backgroundColor: Colors.GREY_200,
    color: '#333',
  },
  '&::after': {
    content: '""',
    position: 'absolute',
    top: '2px',
    right: '2px',
    width: '5px',
    height: '5px',
    backgroundColor: trend && trend >= 3 ? '#6CAF51' : trend && trend >= 2 ? '#E47D31' : '#E54D42',
  },
}));

interface ResultTurnoverButton {
  car: CarResult;
  column: 'etr' | 'itr' | 'apr';
  hideLabel?: boolean;
  searchParamKey: BooleanAppSearchParam;
}

export const ResultTurnoverButton = React.memo(
  ({ car, column, hideLabel, searchParamKey }: ResultTurnoverButton) => {
    const { user } = useContext(UserContext);

    const { t } = useTranslation();

    const value = column == 'apr' ? car.apr : car.stat ? car.stat[searchParamKey] : undefined;
    const title = column.toUpperCase();
    const [carTurnover, setCarTurnover] = useState<CarTurnover | undefined>(
      car.turnover
        ? { ...car.turnover, etr_trend: car.stat?.turnover_ext_trend, itr_trend: car.stat?.turnover_int_trend }
        : undefined,
    );

    useEffect(() => {
      setCarTurnover(undefined);
    }, [car]);

    const trendIcon = useMemo(() => {
      const trendValue = carTurnover ? carTurnover[`${column}_trend`] : undefined;
      if (trendValue == null) return undefined;
      if (trendValue && trendValue < 0) {
        return <ArrowDropDownIcon />;
      }
      if (trendValue && trendValue > 0) {
        return <ArrowDropUpIcon />;
      }
      return <RemoveIcon />;
    }, [carTurnover, column]);

    return column === 'itr' &&
      (!user.permissions.includes('itr') ||
        (car.stat?.own_supply_window_count && car.stat?.own_supply_window_count < 2)) ? (
      <>&nbsp;</> // By returning a space it will still take up the space which otherwise will be filled by other data.
    ) : (
      <TurnOverWrapper>
        {!hideLabel && <span style={{ display: 'inline-block', minWidth: '35px' }}>{column.toUpperCase()}:</span>}

        <Tooltip
          onHoverContent={
            <Grid container>
              <Grid item xs={1}>
                <span>{title}</span>
              </Grid>
              <Grid item xs={11}>
                <Box component="span" mt={2}>
                  {trendIcon}
                </Box>
              </Grid>
              <Grid item xs={12}>
                {column != 'apr' ? (
                  <div>
                    <CarTurnoverChartTiny
                      car={car}
                      column={column}
                      carTurnover={carTurnover}
                      setCarTurnover={setCarTurnover}
                    />
                  </div>
                ) : (
                  <Table>
                    <TableBody>
                      <tr>
                        <TableCell>{t('RATING')}</TableCell>
                        <TableCell>{t('CORRECTION_ABR')}</TableCell>
                        <TableCell>{t('VALUE')}</TableCell>
                      </tr>
                      {Object.entries(car.apr_breakdown || {}).map(([k, v]) => (
                        <tr key={k}>
                          <TableCell style={{ textAlign: 'left' }}>{t(k.toUpperCase())}</TableCell>
                          <TableCell style={{ minWidth: '40px' }}>{v.points}</TableCell>
                          <TableCell style={{ minWidth: '40px' }}>{Math.round(v.bound * 100) / 100 || '-'}</TableCell>
                        </tr>
                      ))}
                    </TableBody>
                  </Table>
                )}
              </Grid>
            </Grid>
          }
        >
          <StyledToggleFilter
            trend={value}
            searchParamKey={searchParamKey}
            labelTranslationKey={value}
            hideResetButton
          />
        </Tooltip>
      </TurnOverWrapper>
    );
  },
  compareMemoProps(['car']),
);
