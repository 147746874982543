import React, { useContext } from 'react';
import { UserContext } from '../../user-context';
import { Grid } from '@mui/material';
import { FirstBar } from './first-bar/first-bar';
import { SecondBar } from './second-bar/second-bar';
import { User, UserRoles } from '../../api/services';

export interface TopRowMenuButtonProps {
  user: User;
  internal: boolean;
}
export interface TopRowWrapperProps {
  internal?: boolean;
  showHintsButton?: boolean;
}
export const TopRow = React.memo(({ internal, showHintsButton = false }: TopRowWrapperProps) => {
  const { user } = useContext(UserContext);

  return (
    <Grid display="flex" gap="10px" flexDirection="column" flex={1}>
      <FirstBar internal={!!internal} showHintsButton={showHintsButton} />
      {user.role != UserRoles.SCRAPE_USER && <SecondBar internal={!!internal} />}
    </Grid>
  );
});
