import Container from '@mui/material/Container';
import React, { ReactNode } from 'react';
import { Box, Grid } from '@mui/material';
import { styled } from '@mui/system';
import { TopRow } from '../top-row/top-row';
import { FiltersWrapper } from '../filter';

const ExtraButtonsRow = ({ children }: { children?: JSX.Element[] | JSX.Element }) => (
  <Box marginTop="20px" marginBottom="20px" display="flex" justifyContent="flex-end">
    <div style={{ flexGrow: 1 }} />
    {children}
  </Box>
);

const StyledGrid = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(2),
})) as typeof Grid;

interface MainViewProps {
  leftMenuComponent?: ReactNode;
  mainComponent?: ReactNode;
  mainExtraButtons?: JSX.Element[];
  leftMenuExtraButtons?: JSX.Element[];
  onClickReset?: () => void;
  hideResetAllButton?: boolean;
  filterCount?: number;
  internal?: boolean;
}
export const PageContainer = ({
  leftMenuComponent,
  mainComponent,
  mainExtraButtons,
  leftMenuExtraButtons,
  filterCount,
  hideResetAllButton = false,
  onClickReset,
  internal = false,
}: MainViewProps) => {
  return (
    <Container sx={{ display: 'flex', flexDirection: 'row' }} maxWidth={'xl'} disableGutters>
      <Grid container>
        <Grid item md={2.5} paddingX={3} sx={{ borderRight: '1px solid rgb(0,0,0,0.20)' }}>
          <FiltersWrapper
            extraButtons={leftMenuExtraButtons}
            hideResetAllButton={hideResetAllButton}
            filterCount={filterCount}
            onClick={onClickReset}
          >
            {leftMenuComponent}
          </FiltersWrapper>
        </Grid>

        <StyledGrid item md={9.5} component={'main'}>
          <Box
            marginBottom={!mainExtraButtons ? '70px' : 0}
            display="flex"
            justifyContent="space-between"
            flexDirection="row"
          >
            <TopRow internal={internal} showHintsButton />
          </Box>
          {mainExtraButtons && mainExtraButtons?.length > 0 && <ExtraButtonsRow>{mainExtraButtons}</ExtraButtonsRow>}
          {mainComponent}
        </StyledGrid>
      </Grid>
    </Container>
  );
};
