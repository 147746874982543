import { FormControlLabel, FormLabel, Switch } from '@mui/material';
import { makeStyles } from '@mui/styles';
import MailIcon from '@mui/icons-material/Mail';
import PhoneIcon from '@mui/icons-material/Phone';
import PersonIcon from '@mui/icons-material/Person';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { MuiTelInput } from 'mui-tel-input';
import { deepClone } from '@japieglobal/shared/src/utils';
import { ColoredButton, ColoredOutlinedButton, ProgressIndicator } from '@japieglobal/shared/src/components';
import { useSnackbarErrorHandler } from '@japieglobal/shared/src/hooks';
import { AdminTextField, FieldWrapper, FormRow } from '../components/styled';
import { AdminMainWrapper } from '../admin-main-wrapper';
import { AdminEditType, AdminPageType, AdminQueryStateType } from '../hooks/admin-query-state';
import { AdminUserDealerEdit } from './admin-user-dealer-edit';
import { AdminUserPermissions } from './admin-user-permissions';
import { AdminUserSuperPassword } from './admin-user-super-password';
import { AdminUserNotifications } from './admin-user-notifications';
import { isValidPhoneNumber } from '../../utils/validate-phone-number';
import { settings } from '@japieglobal/shared/src/settings';
import {
  getUserById,
  updateUser,
  User,
  userCreate,
  UserRoles,
  userSendVerificationMail,
} from '@japieglobal/shared/src/api/services';
import { NetworkError } from '@japieglobal/shared/src/api/network-error';
import { UserContext } from '@japieglobal/shared/src/user-context';

const useStyles = makeStyles(() => ({
  fixTextField: {
    '& .MuiInputBase-root': {
      backgroundColor: 'inherit',
    },
  },
  paddingLeft: {
    paddingLeft: '20px',
  },
}));

const catchDuplicateEmailError =
  (snackbarErrorMessage: (val: string) => void, t: (str: string) => string) => (err: Error) => {
    if (err instanceof NetworkError) {
      if (err.response.status === 400) {
        snackbarErrorMessage(t('ERROR_EMAIL_EXISTS_ALREADY'));
        return;
      }
    }
    throw err;
  };

interface AdminMainEditUserComponentProps {
  adminQueryState: AdminQueryStateType;
  pageType: AdminPageType;
}

export const AdminMainEditUser = React.memo(({ adminQueryState, pageType }: AdminMainEditUserComponentProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const navigate = useNavigate();
  const { snackbarErrorHandler, snackbarErrorMessage, snackbarSuccessMessage } = useSnackbarErrorHandler();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { user } = useContext(UserContext);
  const [dealers, setDealers] = useState<{ id: string; locations: string[] }[]>([{ id: user.dealer, locations: [] }]);

  const { userId, editType } = adminQueryState;

  const isAdding = pageType === AdminPageType.ADD_USER;
  const isServiceAccount = editType === AdminEditType.SERVICE_ACCOUNTS;
  const isEdit = pageType === AdminPageType.EDIT_USER;
  const title = isAdding ? t('ADD_NEW') : t('EDIT_USER');
  const isSuperAdmin = user.role === UserRoles.SUPER_ADMIN;
  const isScrape = editType === AdminEditType.SCRAPE_USER;

  const [editUser, setEditUser] = useState<Partial<User>>({});

  const firstName = useMemo(() => editUser?.first_name, [editUser]);
  const lastName = useMemo(() => editUser?.last_name, [editUser]);
  const email = useMemo(() => editUser?.email, [editUser]);
  const phoneNumber = useMemo(() => editUser?.phone_number, [editUser]);
  const isScraperManager = user.role === UserRoles.SCRAPE_USER && user.permissions.includes('scrapeManagement');
  const hasMonitoringPermission = user.permissions.includes('monitoring');
  const permissions = useMemo(
    () =>
      editUser?.permissions ? editUser.permissions : isScraperManager || !hasMonitoringPermission ? [] : ['monitoring'],
    [editUser.permissions, isScraperManager, hasMonitoringPermission],
  );
  const notifications = useMemo(() => (editUser?.notifications ? editUser.notifications : []), [editUser]);
  const isKey = useMemo(() => editUser?.is_key ?? false, [editUser]);
  const enable2FA = useMemo(() => editUser?.two_factor_authentication ?? true, [editUser]);
  const isAdmin = useMemo(
    () => (editUser?.role ? editUser.role === UserRoles.ADMIN : editType === AdminEditType.ADMIN),
    [editType, editUser.role],
  );
  const quotas = useMemo(() => editUser?.quotas, [editUser]);

  const catchDuplicateEmailErrorProxy = useMemo(
    () => catchDuplicateEmailError(snackbarErrorMessage, t),
    [snackbarErrorMessage, t],
  );

  const setDealersProxy = useCallback((val: { id: string; locations: string[] }[]) => {
    setEditUser((prevState) => {
      const newResult = {
        ...prevState,
        allowed_dealers: [] as string[],
        allowed_locations: {} as Record<string, string[]>,
      };
      if (val && val.length > 0) {
        val.forEach((dealer) => {
          newResult.allowed_dealers!.push(dealer.id);
          newResult.allowed_locations![dealer.id] = dealer.locations;
        });
      }
      return newResult;
    });
  }, []);

  useEffect(() => {
    if (isAdding) {
      const serviceAccount = editType === AdminEditType.SERVICE_ACCOUNTS;
      const quota = serviceAccount ? {} : { api: 250 };
      const role = isScrape
        ? UserRoles.SCRAPE_USER
        : editType === AdminEditType.ADMIN
          ? UserRoles.ADMIN
          : UserRoles.USER;
      setEditUser((prevState) => {
        return { ...prevState, is_service_account: serviceAccount, quotas: quota, role: role };
      });
    }
  }, [isAdding, editType, isScrape]);

  const updateEditUser = <K extends keyof Partial<User>>(value: Partial<User>[K], key: K) => {
    setEditUser((prevState) => ({ ...(prevState || {}), [key]: value }));
  };

  const handleChangeQuota = (event: React.ChangeEvent<HTMLInputElement>) => {
    let newQuota: Record<string, number | undefined>;
    if (event?.target?.value) {
      newQuota = { ...quotas, ...{ api: parseInt(event.target.value, 10) } };
    } else {
      newQuota = { ...quotas };
      delete newQuota.api;
    }
    updateEditUser(newQuota, 'quotas');
  };

  const phoneNumberIsValid = useMemo(() => {
    return isValidPhoneNumber(phoneNumber);
  }, [phoneNumber]);

  const handleCancel = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const handleSendVerification = useCallback(() => {
    if (userId) {
      userSendVerificationMail({ userId })
        .then(() => {
          snackbarSuccessMessage('Verificatiemail is verstuurd.');
        })
        .catch(snackbarErrorHandler);
    }
  }, [snackbarErrorHandler, snackbarSuccessMessage, userId]);

  useEffect(() => {
    if (isEdit && userId?.trim().length === 0) {
      handleCancel();
    }
  }, [handleCancel, isEdit, userId]);

  useEffect(() => {
    if (isEdit && userId) {
      setIsLoading(true);
      getUserById({ userId })
        .then(setEditUser)
        .catch(snackbarErrorHandler)
        .finally(() => setIsLoading(false));
    }
  }, [isEdit, snackbarErrorHandler, userId]);

  useEffect(() => {
    if (editUser) {
      const newDealerName = isSuperAdmin ? '' : user.dealer;
      setDealers(
        editUser.allowed_dealers
          ?.filter((entry) => {
            if (!isSuperAdmin && !isScraperManager) {
              return entry === user.dealer;
            }
            return true;
          })
          .map((value) => {
            return {
              id: value,
              locations:
                editUser.allowed_locations && editUser.allowed_locations[value]
                  ? editUser.allowed_locations[value]
                  : [],
            };
          }) ?? [{ id: newDealerName, locations: [] }],
      );
    }
  }, [editUser, isSuperAdmin, user.dealer, isScraperManager]);

  const handleSubmit = React.useCallback(
    (event) => {
      event.preventDefault();
      // eslint-disable-next-line no-underscore-dangle
      const id = editUser?.id;

      const userSubmit = deepClone(editUser);

      if (!phoneNumberIsValid) {
        userSubmit.phone_number = undefined;
      }

      userSubmit.permissions = userSubmit.permissions || [];
      userSubmit.notifications = userSubmit.notifications || [];
      userSubmit.allowed_dealers = [];
      userSubmit.allowed_locations = {};
      userSubmit.two_factor_authentication = enable2FA;
      if (isScrape) {
        userSubmit.role = UserRoles.SCRAPE_USER;
        userSubmit.allowed_locations = { 'jp.cars': [] }; // TODO: temp workaround
      }
      if (dealers && dealers.length > 0) {
        dealers.forEach((dealer) => {
          if (dealer.id && dealer.id.trim().length > 0) {
            userSubmit.allowed_dealers!.push(dealer.id.trim());
            userSubmit.allowed_locations![dealer.id.trim()] = dealer.locations;
          }
        });
      }

      // eslint-disable-next-line prefer-destructuring
      userSubmit.dealer = userSubmit.allowed_dealers[0];
      userSubmit.locations = userSubmit.allowed_locations[userSubmit.dealer];
      if (isAdding) {
        userSubmit.is_verified = false;
      }

      if (id && userSubmit && isEdit) {
        setIsLoading(true);
        updateUser({ id, body: userSubmit as User })
          .then(handleCancel)
          .catch(catchDuplicateEmailErrorProxy)
          .catch(snackbarErrorHandler)
          .finally(() => setIsLoading(false));
      }
      if (userSubmit && isAdding) {
        setIsLoading(true);
        userCreate({ user: userSubmit as User })
          .then(() => {
            snackbarSuccessMessage(
              'Het account is succesvol aangemaakt. Er is een e-mail verstuurd naar de nieuwe gebruiker.',
            );
          })
          .then(handleCancel)
          .catch(catchDuplicateEmailErrorProxy)
          .catch(snackbarErrorHandler)
          .finally(() => setIsLoading(false));
      }
    },
    [
      editUser,
      phoneNumberIsValid,
      enable2FA,
      isScrape,
      dealers,
      isAdding,
      isEdit,
      handleCancel,
      catchDuplicateEmailErrorProxy,
      snackbarErrorHandler,
      snackbarSuccessMessage,
    ],
  );

  return (
    <AdminMainWrapper title={title}>
      <div />
      <div style={{ width: '800px', padding: '20px', fontSize: '16px' }} className={classes.fixTextField}>
        <form>
          {isEdit && isSuperAdmin && (
            <FormRow>
              <div>
                <FormLabel>
                  {t('CREATED_AT')} {new Date(editUser.created_at!).toLocaleDateString()}
                </FormLabel>
              </div>
              <div>
                <FormLabel>
                  {t('CREATED_BY')} {editUser.created_by}
                </FormLabel>
              </div>
            </FormRow>
          )}
          <FormRow>
            <FieldWrapper>
              <PersonIcon />
              <AdminTextField
                style={{ marginLeft: '10px' }}
                fullWidth
                required
                placeholder={isServiceAccount ? 'API User label' : t('FIRST_NAME')}
                value={firstName}
                onChange={({ target }) => updateEditUser(target.value, 'first_name')}
              />
            </FieldWrapper>
            {!isServiceAccount && (
              <FieldWrapper className={classes.paddingLeft}>
                <PersonIcon />
                <AdminTextField
                  style={{ marginLeft: '10px' }}
                  fullWidth
                  required
                  placeholder={t('LAST_NAME')}
                  value={lastName}
                  onChange={({ target }) => updateEditUser(target.value, 'last_name')}
                />
              </FieldWrapper>
            )}
          </FormRow>
          {!isServiceAccount && (
            <>
              <FormRow>
                <FieldWrapper>
                  <MailIcon />
                  <AdminTextField
                    style={{ marginLeft: '10px' }}
                    type="email"
                    fullWidth
                    required
                    placeholder="Email"
                    value={email}
                    onChange={({ target }) => updateEditUser(target.value, 'email')}
                  />
                </FieldWrapper>
                {isSuperAdmin && (
                  <FieldWrapper className={classes.paddingLeft}>
                    <PhoneIcon />
                    <MuiTelInput
                      style={{ marginLeft: '10px' }}
                      value={phoneNumber}
                      onChange={(value) => updateEditUser(value.replaceAll(' ', ''), 'phone_number')}
                      defaultCountry={settings().country.toUpperCase()}
                      error={!isValidPhoneNumber(phoneNumber)}
                    />
                  </FieldWrapper>
                )}
              </FormRow>
              {isSuperAdmin && (
                <FormRow>
                  <FieldWrapper>
                    <div>
                      <FormControlLabel
                        control={
                          <Switch
                            color="primary"
                            checked={enable2FA}
                            onChange={({ target }) => updateEditUser(target.checked, 'two_factor_authentication')}
                            name="enable2FA"
                          />
                        }
                        label="Enable 2 factor authentication"
                      />
                    </div>
                    {/*<div>{user.created_by}</div>*/}
                  </FieldWrapper>
                </FormRow>
              )}
              {!isScrape && (
                <FormRow>
                  <FieldWrapper>
                    <div>
                      <FormControlLabel
                        control={
                          <Switch
                            color="primary"
                            checked={isAdmin}
                            onChange={({ target }) =>
                              updateEditUser(target?.checked ? UserRoles.ADMIN : UserRoles.USER, 'role')
                            }
                            name="isAdmin"
                          />
                        }
                        label="Admin"
                      />
                    </div>
                    {isSuperAdmin && (
                      <div>
                        <FormControlLabel
                          control={
                            <Switch
                              color="primary"
                              checked={isKey}
                              onChange={({ target }) => updateEditUser(target.checked, 'is_key')}
                              name="isKey"
                            />
                          }
                          label="Key User"
                        />
                      </div>
                    )}
                  </FieldWrapper>
                </FormRow>
              )}
            </>
          )}
          {!isScrape && (
            <FormRow>
              <AdminUserDealerEdit dealers={dealers} setDealers={setDealersProxy} />
            </FormRow>
          )}
          {!isServiceAccount && (
            <>
              <FormRow>
                <AdminUserPermissions
                  permissions={permissions}
                  setPermissions={(permissions) => updateEditUser(permissions, 'permissions')}
                  isScrape={isScrape}
                  editUser={editUser}
                />
              </FormRow>
              {!isScrape && (
                <FormRow>
                  <AdminUserNotifications
                    role={editUser.role}
                    notifications={notifications}
                    setNotifications={(nofitcations) => updateEditUser(nofitcations, 'notifications')}
                  />
                </FormRow>
              )}
            </>
          )}
          {isSuperAdmin && !isScrape && (
            <FormRow>
              <FieldWrapper>
                <AdminTextField
                  style={{ marginLeft: '10px' }}
                  type="number"
                  fullWidth
                  placeholder="API Quota"
                  value={!quotas?.api ? '' : quotas.api}
                  onChange={handleChangeQuota}
                />
              </FieldWrapper>
            </FormRow>
          )}
          {editUser.is_service_account && isEdit && (
            <FormRow>
              <div>
                <strong>Token</strong>: {editUser.token}
              </div>
            </FormRow>
          )}
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <ColoredOutlinedButton onClick={handleCancel}>{t('CANCEL')}</ColoredOutlinedButton>
            <ColoredButton style={{ marginLeft: '10px' }} onClick={handleSubmit}>
              {isAdding ? t('ADD') : t('SAVE')}
            </ColoredButton>
          </div>
          {/* eslint-disable-next-line no-underscore-dangle */}
          {editUser && editUser.id && editUser.is_verified !== true && (
            <div style={{ display: 'flex' }}>
              <ColoredButton onClick={handleSendVerification}>{t('SEND_VERIFICATION_MAIL')}</ColoredButton>
            </div>
          )}
        </form>
        {/* eslint-disable-next-line no-underscore-dangle */}
        {editUser && editUser.id && isEdit && isSuperAdmin && !editUser.is_service_account && (
          <AdminUserSuperPassword editUser={editUser} setEditUser={updateEditUser} />
        )}
      </div>

      {isLoading && <ProgressIndicator />}
    </AdminMainWrapper>
  );
});
