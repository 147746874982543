import { useTranslation } from 'react-i18next';
import { FormControlLabel, Switch } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Notification, notificationList, UserRoles } from '@japieglobal/shared/src/api/services';

interface AdminUserNotificationsProps {
  notifications: string[];
  setNotifications: (val: string[]) => void;
  role?: UserRoles;
}

export const AdminUserNotifications = ({ role, setNotifications, notifications }: AdminUserNotificationsProps) => {
  const { t } = useTranslation();

  const mapOptions = useCallback(
    (options: Notification[]) => {
      return options.map((notification) => {
        let formattedName = notification.name;
          if (!['auto_save_taxations', 'super_admin_master_mode'].includes(formattedName)) {
            formattedName = formattedName + '_email';
          }
        formattedName = formattedName.toUpperCase();
        return {
          key: notification.name,
          name: t(formattedName),
        };
      });
    },
    [t],
  );
  const [notificationOptions, setNotificationOptions] = useState<Notification[]>([]);
  useEffect(() => {
    notificationList(role).then((r) => setNotificationOptions(r));
  }, [role]);

  const featuresCol1: Array<{ key: string; name: string }> = useMemo(() => {
    const notificationsFirstColumn = notificationOptions.slice(0, notificationOptions.length / 2);
    return mapOptions(notificationsFirstColumn);
  }, [mapOptions, notificationOptions]);

  const featuresCol2: Array<{ key: string; name: string }> = useMemo(() => {
    const notificationsSecondColumn = notificationOptions.slice(notificationOptions.length / 2);
    return mapOptions(notificationsSecondColumn);
  }, [mapOptions, notificationOptions]);

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.checked) {
        setNotifications([...notifications, event.target.name]);
      } else {
        setNotifications(notifications.filter((f) => f !== event.target.name));
      }
    },
    [notifications, setNotifications],
  );

  const renderSwitch = (feature: { name: string; key: string }) => {
    return (
      <div key={feature.key}>
        <FormControlLabel
          control={
            <Switch
              color="primary"
              checked={notifications.includes(feature.key) ?? false}
              onChange={handleChange}
              name={feature.key}
            />
          }
          label={feature.name}
        />
      </div>
    );
  };

  return (
    <div style={{ width: '100%' }}>
      <div style={{ marginBottom: '18px' }}>
        <strong>{t('NOTIFICATIONS')}</strong>
      </div>
      <div style={{ display: 'flex' }}>
        <div
          style={{
            display: 'flex',
            width: '50%',
            flexDirection: 'column',
          }}
        >
          {featuresCol1.map(renderSwitch)}
        </div>
        <div
          style={{
            display: 'flex',
            width: '50%',
            flexDirection: 'column',
          }}
        >
          {featuresCol2.map(renderSwitch)}
        </div>
      </div>
    </div>
  );
};
