import React from 'react';
import { TextFilter, AppSearchParamKeys } from '@japieglobal/shared';
import { Grid } from '@mui/material';

export const TaxationFilterLicensePlate = () => (
  <Grid item xs={2}>
    <TextFilter
      tabOnEnter
      searchParamKey={AppSearchParamKeys.LICENSE_PLATE}
      labelTranslationKey="LICENSE_PLATE"
      autoFocus
      hideResetButton
    />
  </Grid>
);
