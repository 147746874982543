import { UserRoles } from '@japieglobal/shared/src/api/services';
import { settings } from '@japieglobal/shared/src/settings';
import { CarResult } from '@japieglobal/shared/src/types';
import { UserContext } from '@japieglobal/shared/src/user-context';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import { styled } from '@mui/styles';
import React, { useContext } from 'react';
import { ColoredButtonSmall } from './results/result-table-styles';


const ScrapeManagementButton = styled(ColoredButtonSmall)({
  height: '25px',
  width: '15px',
  position: 'absolute',
  bottom: 0,
  right: 0,
  border: 0,
});

export function CarScrapeAdminButton({ car, marginBottom }: { car: CarResult, marginBottom?: number }) {
  const { user } = useContext(UserContext);
  const hasScrapePermission =
    user?.role === UserRoles.SUPER_ADMIN &&
    (user.permissions.includes('scrapeUser') || user.permissions.includes('scrapeManagement'));
  const showScrapeLink = hasScrapePermission && car.url && settings().country == car.country?.toLowerCase();

  if (!showScrapeLink) return undefined;

  return (
    <ScrapeManagementButton style={{ marginBottom: marginBottom ?? 8 }}
      href={`/admin-scrape/items/production/${car.dealer?.name}?tab=files&url_api=${car.url}`}
    >
      <LibraryBooksIcon style={{ width: 16 }} />
    </ScrapeManagementButton>
  );
}
