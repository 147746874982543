import React from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material';
import { CarResult } from '@japieglobal/shared/src/types/car-types';
import { Tooltip } from '@japieglobal/shared/src/components';
import { formatThousands } from '../../../utils/number-format';

const ThStyled = styled(`th`)({ fontWeight: 'bold', textAlign: 'left' });

export const CarWindowDeltaCell = React.memo(({ car }: { car: CarResult }) => {
  const { t } = useTranslation();

  return (
    <Tooltip
      onHoverContent={
        car.stat?.price_start && car.price?.history && car.price.history.length > 0 ? (
          <table>
            <thead>
              <tr>
                <ThStyled>{t('DATE')}</ThStyled>
                <ThStyled>{t('DISCOUNT')}</ThStyled>
              </tr>
            </thead>
            <tbody>
              {car.price?.history.map((history) => (
                <tr key={`${car.id}-price-history-${history.label}`}>
                  <td>{history.label}</td>
                  <td>€ {formatThousands(history.value)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : undefined
      }
    >
      <span>€ {formatThousands(car.stat?.price_start)}</span>
    </Tooltip>
  );
});
