import React, { useCallback, useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import { styled, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { CarResult } from '@japieglobal/shared/src/types/car-types';
import { CarMessages } from './car-messages';
import { getCarId } from '../../utils/car-id';
import { CarMessageContextSetterGetter } from '../../../../shared/src/car-message/car-message-context';
import { ColoredButton } from '@japieglobal/shared';
import { carMessageMarkAsRead, sendCarMessageEmail } from '@japieglobal/shared/src/api/services';
import { sendMessage } from '@japieglobal/shared/src/api/services/common';

const useStyles = makeStyles({
  dialogPaper: {
    minHeight: '80vh',
    maxHeight: '80vh',
  },
});

const CommentBoxForm = styled(DialogContent)({
  minHeight: '95px',
  maxHeight: '95px',
  display: 'flex',
  position: 'relative',
});

const StyledDialogTitle = styled(DialogTitle)({
  textAlign: 'center',
});

const CommentBoxText = styled(`input`)({
  width: '100%',
  border: '1px solid #dee1e2',
  padding: '20px 150px 20px 20px',
  maxWidth: '100%',
  maxHeight: '60px',
  height: 'auto !important',
  resize: 'none',
});

interface CarMessageDialog {
  car: CarResult;
  showDialog: boolean;
  setShowDialog: (val: boolean) => void;
  carMessageContext: CarMessageContextSetterGetter;
}
export const CarMessageDialog = React.memo(
  ({ car, showDialog, setShowDialog, carMessageContext }: CarMessageDialog) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const handleClose = useCallback(() => {
      if ((carMessageContext?.value?.carMessagesByCarId![car.id!] || []).some((c) => !c.read)) {
        carMessageMarkAsRead({ car_id: getCarId(car) }).then();
        carMessageContext.setter({
          carMessagesByCarId: {
            ...carMessageContext.value?.carMessagesByCarId,
            [getCarId(car)]: carMessageContext!.value!.carMessagesByCarId![getCarId(car)].map((c) => ({
              ...c,
              read: true,
            })),
          },
          unreadCount: carMessageContext!.value!.unreadCount! - 1,
        });
      }
      setShowDialog(false);
    }, [car, carMessageContext, setShowDialog]);

    const [newComment, setNewComment] = useState('');

    const [commentListContainer, setCommentListContainer] = useState<HTMLDivElement | undefined>(undefined);

    const scrollToBottom = useCallback(() => {
      const container = commentListContainer;
      if (container) {
        container.scrollTop = container.scrollHeight;
      }
    }, [commentListContainer]);

    const postComment = useCallback(() => {
      if (newComment && newComment.length > 0) {
        sendMessage({
          car_id: getCarId(car),
          content: newComment,
        })
          .then((response) => {
            setNewComment('');
            carMessageContext.setter({
              carMessagesByCarId: {
                ...carMessageContext.value?.carMessagesByCarId,
                [getCarId(car)]: [...carMessageContext!.value!.carMessagesByCarId![getCarId(car)], response],
              },
              unreadCount: carMessageContext!.value!.unreadCount! - 1,
            });
          })
          .then(() =>
            sendCarMessageEmail({
              license_plate: car.license_plate!,
              make: car.make!,
              model: car.model!,
              location_name: car.location_name!,
              car_id: String(car.id!),
            }),
          );
      }
    }, [car, newComment, carMessageContext]);

    useEffect(() => {
      if (commentListContainer && showDialog === true) {
        scrollToBottom();
      }
    }, [commentListContainer, scrollToBottom, showDialog]);

    return (
      <Dialog
        fullScreen={fullScreen}
        maxWidth="md"
        fullWidth
        onClose={handleClose}
        aria-labelledby="export"
        open={showDialog}
        disableEnforceFocus
        classes={{ paper: classes.dialogPaper }}
      >
        <StyledDialogTitle>
          <div>
            {car.make} {car.model}
          </div>
          <div>{car.license_plate}</div>
        </StyledDialogTitle>
        {/* eslint-disable-next-line no-return-assign */}
        <DialogContent ref={setCommentListContainer} dividers style={{ minHeight: 'calc(80% - 64px)' }}>
          {carMessageContext.value && (
            <CarMessages scrollToBottom={scrollToBottom} carMessageContext={carMessageContext.value} car={car} />
          )}
        </DialogContent>
        <CommentBoxForm dividers>
          <CommentBoxText
            value={newComment}
            onChange={(event) => setNewComment(event.target.value)}
            type="text"
            placeholder={t('WRITE_YOUR_MESSAGE_HERE')}
            onKeyPress={(event) => (event.key === 'Enter' ? postComment() : undefined)}
          />
          <ColoredButton type="submit" onClick={postComment}>
            {t('SEND') as string}
          </ColoredButton>
        </CommentBoxForm>
      </Dialog>
    );
  },
);
